.card {
	display: block;
	position: relative;
	border: 1px solid @line;
	transition: none;
	flex: 1 1 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0 1px;
	padding-bottom: 100%;
	overflow: hidden;
	.resp(999px,{
		padding: 0;
		display: flex;
		align-items: stretch;
	});
	&:hover {
		.card-main {
			height: 100%;
			padding-bottom: 100px;
			.resp(1279px,{
				padding-bottom: 70px;
			});
			.resp(999px,{
				flex: 1 1 100%;
				max-width: 100%;
			});
			&:after {
				height: 48px;
				margin-top: 32px;
				border: 1px solid @red;
				opacity: 1;
				.resp(1279px,{
					height: 40px;
					margin-top: 16px;
					border: 0;
				});
			}
			.card-icon {
				height: 48px;
				margin-bottom: 24px;
				opacity: 1;
				.resp(1279px,{
					height: 40px;
					margin-bottom: 16px;
				});
			}
		}
		.cover {
			.resp(999px,{
				opacity: 1;
			});
		}
	}
	.card-main {
		padding: 32px;
		background: #FFF;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 50%;
		box-sizing: border-box;
		z-index: 2;
		transition: all @hover;
		.resp(1279px,{
			padding: 16px;
		});
		.resp(999px,{
			position: relative;
			height: 100%;
			transition: none;
			padding-bottom: 70px;
		});
		&:after {
			border-radius: 50%;
			height: 0;
			overflow: hidden;
			width: 48px;
			box-sizing: border-box;
			.svg('rarr',@red);
			background-size: 18px 11px;
			background-position: center center;
			content: '';
			position: absolute;
			left: 40px;
			bottom: 40px;
			opacity: 0;
			transition: all @hover;
			.resp(1279px,{
				width: 40px;
				left: 20px;
				bottom: 20px;
			});
			.resp(999px,{
				height: 40px;
				border: 0;
				left: 10px;
				opacity: 1;
				transition: none;
				.svg('rarr',@black);
				background-size: 18px 11px;
				background-position: center center;
			});
		}
		.card-icon {
			width: 48px;
			height: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 1;
			position: relative;
			overflow: hidden;
			opacity: 0;
			transition: all @hover;
			.resp(1279px,{
				width: 40px;
			});
			.resp(999px,{
				height: 40px;
				margin-bottom: 16px;
				opacity: 1;
				transition: none;
			});
			> img {
				max-width: 48px;
				max-height: 48px;
				display: block;
				flex: 0 0 auto;
				.resp(1279px,{
					max-width: 40px;
					max-height: 40px;
				});
			}
		}
		.card-text {
			.resp(1279px,{
				font-size: 14px;
				line-height: 24px;
			});
		}
	}
	.cover {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		.resp(999px,{
			z-index: 2;
			opacity: 0;
			transition: opacity @hover;
		});
		.resp(767px,{
			display: none;
		});
		&:after {
			.resp(999px,{
				width: 40px;
				height: 40px;;
				border-radius: 50%;
				box-sizing: border-box;
				.svg('rarr',#FFF);
				background-size: 18px 11px;
				background-position: center center;
				content: '';
				position: absolute;
				left: 10px;
				bottom: 20px;
				border: 1px solid #FFF;
			});
		}
	}
}

.card-grid {
	&.carousel-mobile-only {
		margin: -16px;
		display: flex;
		flex-wrap: wrap;
		display: flex;
		align-items: stretch;
		.resp(767px,{
			display: block;
			margin: 0;
		});
		.card-box {
			padding: 16px;
			flex: 0 0 25%;
			max-width: 25%;
			.resp(999px,{
				flex: 0 0 50%;
				max-width: 50%;
			});
			.resp(767px,{
				flex: 1 1 100%;
				max-width: 100%;
				padding: 0;
			});
		}
		.owl-carousel {
			flex: 1 1 100%;
			max-width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: stretch;
			.resp(767px,{
				display: block;
				max-width: none;
				.owl-stage {
					display: flex;
					align-items: stretch;
					.owl-item {
						flex: 1 1 auto;
						min-width: 0;
						float: none;
						height: auto;
						display: flex;
						align-items: stretch;
						padding: 0 1px;
					}
				}
			});
		}
	}
	&:not(.carousel-mobile-only) {
		.card-box {
			flex: 1 1 100%;
			max-width: 100%;
			padding: 0;
		}
		.owl-stage {
			display: flex;
			align-items: stretch;
			.owl-item {
				flex: 1 1 auto;
				min-width: 0;
				float: none;
				height: auto;
				display: flex;
				align-items: stretch;
				padding: 0 1px;
			}
		}
	}
	.card-box {
		box-sizing: border-box;
		display: flex;
		align-items: stretch;
	}
}

.card-carousel {
	.owl-carousel {
		.owl-stage {
			display: flex;
			align-items: stretch;
			.owl-item {
				flex: 1 1 auto;
				min-width: 0;
				float: none;
				height: auto;
				display: flex;
				align-items: stretch;
				padding: 0 1px;
			}
		}
	}
}

.card-documents {
	padding: 16px;
		flex: 0 0 25%;
		max-width: 25%;
		box-sizing: border-box;
		display: flex;
		align-items: stretch;
		.resp(1100,{
			flex: 0 0 50%;
			max-width: 50%;
		});
		.resp(767px,{
			flex: 1 1 100%;
			max-width: 100%;
			padding: 0;
		});

		.card {
			display: block;
			position: relative;
			border: 1px solid @line;
			transition: none;
			flex: 1 1 100%;
			max-width: 100%;
			box-sizing: border-box;
			margin: 0 1px;
			padding-bottom: 100%;
			overflow: hidden;
			.resp(1100px,{
				padding: 0;
				display: flex;
				align-items: stretch;
			});
			.card-main-document {
				padding: 40px;
				background: #FFF;
				position: absolute;
				width: 100%;
				min-height: 100%;
				box-sizing: border-box;
				z-index: 2;
				transition: all @hover;
				.resp(1279px,{
					padding: 20px;
				});
				.resp(1100px,{
					position: relative;
					min-height: 0;
					transition: none;
					padding-bottom: 15px;
				});
				.card-text {
					.resp(1279px,{
						font-size: 14px;
						line-height: 24px;
					});
				}
				.card-documents-image{
					padding-top: 20px;
					bottom: 10%;
				}
				.more-link{
					position: absolute;
					bottom: 10%;
				}
			}
		}
}
