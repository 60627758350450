.video-gallery {
	margin: -32px -16px;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	.resp(999px,{
		margin: -24px -16px;
	});
	.resp(767px,{
		display: block;
		margin: 0;
	});
	> li {
		padding: 32px 16px;
		flex: 0 0 50%;
		max-width: 50%;
		box-sizing: border-box;
		.resp(999px,{
			padding: 24px 16px;
		});
		.resp(767px,{
			max-width: none;
			padding: 0;
			& + li {
				margin-top: 40px;
			}
		});
		> h4 {
			margin: 40px 0 0 0;
			.resp(999px,{
				margin-top: 30px;
			});
			.resp(767px,{
				margin-top: 24px;
			});
		}
	}
}