.section-form {
	padding: 140px 0;
	position: relative;
	overflow: hidden;

	.resp(1279px,{
		padding: 88px 0;
	});

	.resp(767px,{
		padding: 48px 0;
	});

	&__inner {
		position: relative;
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		column-gap: 32px;
		padding-left: 16px;
		padding-right: 16px;

		.resp(1279px,{
			position: static;
		});

		.resp(767px,{
			grid-template-columns: repeat(1, minmax(0, 1fr));
			row-gap: 24px;
		});

		&::before,
		&::after {
			content: '';
			height: calc(100% + 280px);
			width: 1px;
			background-color: @gray;
			position: absolute;
			top: -140px;
			z-index: -1;
		}

		&::before {
			left: -160px;

			.resp(1279px,{
				left: 16px;
			});
		}

		&::after {
			right: -160px;

			.resp(1279px,{
				right: 16px;
			});
		}
	}

	&__content {
		max-width: 502px;

		.block-header {
			margin-bottom: 16px;
		}

		p {
			margin-top: 0;
			margin-bottom: 8px;
		}
	}

	&__form {
		max-width: 464px;
		position: relative;

		.resp(479px,{
			display: flex;
			flex-direction: column;
			align-items: center;
		});
	}

	&__submit {
		margin-top: 56px;

		.resp(767px,{
			margin-top: 40px;
		});
	}

	&__decor {
		width: 140px;
		height: 72px;
		display: flex;
		flex-direction: column;
		row-gap: 16px;
		position: absolute;
		top: -72px;
		right: -230px;

		.resp(1279px,{
			width: 82px;
			height: 42px;
			row-gap: 9px;
			right: -18px;
			top: 32px;
		});

		.resp(767px,{
			width: 57px;
			height: 30px;
			row-gap: 6px;
			right: -8px;
			top: 24px;
		});

		& > span {
			height: 100%;

			&:first-child {
				background-color: @black;
			}
			&:last-child {
				background-color: @red;
			}
		}
	}

	&__error {
		margin-top: 10px;
		color: @red;

		&:empty {
			margin-top: 0;
		}
	}

	&__success {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		row-gap: 20px;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #fff;
		text-align: center;

		&.hide {
			opacity: 0;
			pointer-events: none;
		}
	}

	&__success-title {
		font-size: 24px;
		font-weight: 700;
		color: @red;
	}

	&__success-text {
		font-weight: 400;
	}

	&--type2 {
		padding-top: 0;

		.section-form__inner {
			&::before,
			&::after {
				display: none;
			}
		}
	}
}
