.import-migration {
    .i-box.i-box-index .i-box-image:after {
      .resp(767px,{
        display: none;
          });
    }
  
    .i-box .i-box-image > img {
      .resp(479px,{
        height: 218px;
        object-fit: cover;
          });
    }
  }
  