.project {
	flex: 1 1 100%;
	max-width: 100%;
	box-sizing: border-box;
	background: @bg;
	padding: 48px 48px 57px 48px;
	display: flex;
	flex-direction: column;
	.resp(767px,{
		padding: 30px;
		display: block;
	});
	.resp(419px,{
		padding: 30px 24px 24px 24px;
	});
	.project-about {
		margin-bottom: 44px;
		.resp(767px,{
			margin-bottom: 32px;
		});
		h4 {
			margin-bottom: 16px;
		}
		.list {
			margin: 0;
		}
		.project-about-box {
			& + .project-about-box {
				margin-top: 24px;
			}
		}
	}
	.project-author {
		margin-bottom: 32px;
		display: flex;
		.resp(1279px,{
			margin-bottom: 24px;
		});
		.resp(767px,{
			margin-bottom: 16px;
		});
		.project-author-about {
			flex: 1 1 auto;
			min-width: 0;
			> p {
				margin: 0;
				line-height: 24px;
			}
			> span {
				font-size: 24px;
				line-height: 36px;
				font-weight: 500;
				display: block;
				margin-bottom: 8px;
				.resp(1279px,{
					font-size: 20px;
					line-height: 30px;
				});
				.resp(767px,{
					font-size: 16px;
					line-height: 28px;
					margin-bottom: 4px;
				});
			}
		}
		.project-author-avatar {
			flex: 0 0 auto;
			width: 64px;
			height: 64px;
			border-radius: 50%;
			display: block;
			margin: 3px 24px 0 0;
			.resp(1279px,{
				width: 56px;
				height: 56px;
				margin-top: 0;
			});
			.resp(767px,{
				width: 40px;
				height: 40px;
			});
		}
	}
	.project-main {
		flex: 1 1 auto;
		width: 100%;
		overflow: hidden;
	}
	.project-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: 0 0 auto;
		width: 100%;
		.more-link {
			flex: 0 0 auto;
		}
		.project-logo {
			display: block;
			height: 48px;
			flex: 0 0 auto;
			.resp(479px,{
				height: 36px;
			});
		}
	}

	

}

.projects {
	display: flex;
	align-items: stretch;
	margin: -16px;
	flex-wrap: wrap;
	.resp(999px,{
		margin: 0;
		display: block;
	});
	.projects-box {
		flex: 0 0 50%;
		max-width: 50%;
		box-sizing: border-box;
		padding: 16px;
		display: flex;
		align-items: stretch;
		.resp(999px,{
			max-width: none;
			padding: 0;
			& + .projects-box {
				margin-top: 40px;
			}
		});
		.resp(767px,{
			& + .projects-box {
				margin-top: 30px;
			}
		});
	}
}

.project.case{
	padding: 0px;
	display: block;
	position: relative;

	.resp(768px,{
		display: none;
	});

	.project-case{
		> img {
			display: block;
			width: 100%;
		}

		> span {
			position: absolute;
			text-transform: uppercase;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			padding: 48px 48px;
			background: none;
			color: #FFF;
			font-size: 48px;
			line-height: 60px;
			font-weight: 700;

			.resp(1279px,{
				padding: 30px 24px;
				font-size: 32px;
				line-height: 48px;
			});
		}
	}	
}