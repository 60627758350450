.form-element {
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 4px;

	& + .form-element {
		margin-top: 16px;
	}

	&__label {
		color: #fff;
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: 0.2px;
	}

	&__field {
		width: 100%;
		position: relative;
	}

	&__input {
		background-color: transparent;
		box-sizing: border-box;
		width: 100%;
		padding: 7px 16px;
		padding-right: 48px;
		border: 1px solid @gray;
		color: #fff;
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: 0.2px;
		transition: @hover;
		outline: none;

		&:hover,
		&:focus {
			border-color: #fff;
		}

		&::placeholder {
			color: @gray;
		}
	}

	&__clean {
		position: absolute;
		width: 16px;
		height: 16px;
		background-color: transparent;
		padding: 0;
		top: 50%;
		right: 16px;
		transform: translateY(-50%);
		border: none;
		cursor: pointer;
		opacity: 0;
		pointer-events: none;
		transition: opacity @hover;
	}

	&__notification {
		font-size: 10px;
		letter-spacing: 0.2px;
		line-height: 1.2;
	}

	&__notification--error {
		color: @red;
	}

	&.is-clean {
		.form-element__clean {
			opacity: 1;
			pointer-events: initial;
		}
	}

	&--black {
		.form-element__label {
			color: @black;
		}

		.form-element__input {
			color: @black;

			&:hover,
			&:focus {
				border-color: @line;
			}
		}
	}
}
