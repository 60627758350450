.section-contacts-new {
	.consult-team-box {
		h4 {
			margin-bottom: 8px;
		}

		span {
			display: block;
			margin-bottom: 16px;
			line-height: 24px;
		}
	}

	.consult-team-image {
		display: flex;
		margin-bottom: 24px;
	}

	.consult-team-box-contacts {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		a {
			font-size: 16px;
			line-height: 24px;

			&:hover {
				color: @red;
			}
		}
	}

	&__title {
		margin-bottom: 40px;
		font-weight: 400;

		.resp(1199px,{
			margin-bottom: 32px;
			font-size: 20px;
		});

		.resp(767px,{
			margin-bottom: 24px;
		});
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(4, minmax(0, 1fr));
		gap: 32px;

		.resp(1023px,{
			grid-template-columns: repeat(3, minmax(0, 1fr));
		});

		.resp(767px,{
			grid-template-columns: repeat(1, minmax(0, 1fr));
		});
	}
}
