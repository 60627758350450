.row {
	display: flex;
	justify-content: space-between;
	.resp(999px,{
		display: block;
		margin: 0;
	});
	&.row-stretch {
		align-items: stretch;
		.col-left,
		.col-right {
			display: flex;
			align-items: stretch;
		}
		.row-stretch-content {
			flex: 1 1 100%;
			max-width: 100%;
		}
	}
	.col-left {
		flex: 0 1 538px;
		max-width: 538px;
		margin-right: 60px;
		.resp(1200px,{
			flex: 0 0 396px;
			max-width: 396px;
		});
		.resp(999px,{
			max-width: none;
			margin: 0 0 40px 0;
		});
		.resp(767px,{
			margin-bottom: 32px;
		});
	}
	.col-right {
		flex: 0 0 638px;
		max-width: 638px;
		.resp(1200px,{
			flex: 1 1 auto;
			max-width: none;
			min-width: 0;
		});
	}
}
