.video-link {
	display: inline-flex;
	align-items: center;
	vertical-align: top;
	max-width: 100%;
	box-sizing: border-box;
	padding-left: 48px;
	min-height: 40px;
	position: relative;
	&:hover {
		&:after {
			.svg('play',@red);
		}
		&:before {
			background: #FFF;
		}
	}
	&:after,
	&:before {
		position: absolute;
		content: '';
	}
	&:after {
		transition: background @hover;
		.svg('play',#FFF);
		z-index: 3;
		width: 9px;
		height: 9px;
		left: 17px;
		top: 15px;
	}
	&:before {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		z-index: 2;
		background: @red;
		transition: background @hover;
		left: 0;
		top: 0;
	}
	> span {
		flex: 0 1 auto;
		display: block;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
	}
}
