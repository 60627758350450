.team-box {
	flex: 0 0 25%;
	max-width: 25%;
	box-sizing: border-box;
	padding: 25px 16px;
	.resp(1279px,{
		padding: 20px 10px;
	});
	.resp(999px,{
		flex: 0 0 33.3333%;
		max-width: 33.3333%;
	});
	.resp(767px,{
		flex: 0 0 50%;
		max-width: 50%;
	});
	.resp(419px,{
		flex: 1 1 100%;
		max-width: 100%;
	});
	.team-image {
		position: relative;
		margin-bottom: 24px;
		&:before {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 50%;
			content: '';
			background: @red;
			padding-bottom: 9%;
		}
		> img {
			width: 100%;
			display: block;
		}
	}
	.team-name {
		font-size: 24px;
		line-height: 36px;
		font-weight: 500;
		margin-bottom: 8px;
		.resp(999px,{
			font-size: 20px;
			line-height: 30px;
		});
		.resp(479px,{
			font-size: 16px;
			line-height: 28px;
		});
	}
	.team-position {
		font-size: 16px;
		line-height: 28px;
		margin-bottom: 24px;
		.resp(999px,{
			font-size: 14px;
			line-height: 22px;
			margin-bottom: 16px;
		});
	}
}

.team-grid {
	display: flex;
	flex-wrap: wrap;
	margin: -25px -16px;
	.resp(1279px,{
		margin: -20px -10px;
	});
}
