.project-block-cover {
	flex: 0 0 50%;
	max-width: 50%;
	box-sizing: border-box;
	padding: 16px;
	display: flex;
	align-items: stretch;
	min-height: 700px;
	position: relative;

	@media screen and (max-width: 999px) {
		display: none;
	}

	&__img {
		width: calc(~'100% - 32px');
		height: calc(~'100% - 32px');
		object-fit: cover;
		object-position: center;
		position: absolute;
	}

	&__text {
		position: relative;
		padding: 48px;
		font-size: 48px;
		font-weight: 700;
		line-height: 60px;
		text-transform: uppercase;
		color: #fff;
	}
}
