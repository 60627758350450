.new-application-option {
  &__inner {
    display: grid;
    grid-template-columns: 400px 1fr 1fr;
    column-gap: 32px;
    align-items: center;

    .resp(1279px,{
			grid-template-columns: 300px 1fr 1fr;
		});

    .resp(999px,{
			grid-template-columns: 1fr 1fr;
      row-gap: 40px;
		});

    .resp(540px,{
      grid-template-columns: 1fr;
      row-gap: 24px;
		});
  }

  .block-header {
    margin-bottom: 0;

    .resp(999px,{
			grid-column: 2 span;
		});

    .resp(540px,{
      grid-column: 1 span;
		});
  }

  .products-new-slide {
    width: 100%;
    margin: 0;
    aspect-ratio: initial;
    .resp(767px,{
      height: 320px;
		});

    .resp(479px,{
      height: 250px;
		});

    &:last-child {
      & > img {
        .resp(767px,{
          object-position: center top;
        });
      }
    }
  }
}
