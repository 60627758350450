.student-feedback {
	display: flex;
	flex-direction: column;
	row-gap: 8px;

	&__header {
		display: flex;
		align-items: flex-end;
		column-gap: 24px;
		background-color: #fff;
		padding: 32px;

		.resp(767px,{
      flex-direction: column;
			align-items: flex-start;
			row-gap: 8px;
			padding: 24px 16px 16px 16px;
    });
	}

	&__avatar {
		width: 140px;
		height: 140px;
		overflow: hidden;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__meta {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		row-gap: 4px;
	}

	&__name {
		font-size: 20px;
		font-weight: 700;
	}

	&__lesson {
	}

	&__body {
		background-color: #fff;
		padding: 32px;

		.resp(767px,{
			padding: 16px 16px 24px 16px;
    });
	}
}
