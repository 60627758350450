.tab {
	visibility: hidden;
	height: 0;
	overflow: hidden;
	&.is-active {
		visibility: visible;
		height: auto;
		overflow: visible;
	}
	&.tab-mobile {
		.resp(999px,{
			visibility: visible;
			height: auto;
			overflow: visible;
			padding: 24px 16px;
			margin: 0 -16px;
			border-top: 1px solid @line;
			border-bottom: 1px solid @line;
		});
		&.is-active {
			.block-header {
				.resp(999px,{
					margin-bottom: 24px;
					&:after {
						top: 15px;
						height: 2px;
						background: @black;
					}
				});
				.resp(767px,{
					margin-bottom: 16px;
					&:after {
						top: 11px;
					}
				});
			}
			.tab-content {
				.resp(999px,{
					display: block;
				});
			}
		}
		&.is-closed {
			.block-header {
				.resp(999px,{
					margin: 0;
					&:after {
						top: 9px;
						height: 14px;
						.svg('plus',@red);
					}
				});
				.resp(767px,{
					margin: 0;
					&:after {
						top: 5px;
					}
				});
			}
			.tab-content {
				.resp(999px,{
					display: none;
				});
			}
		}
		.block-header {
			.resp(999px,{
				padding: 0 20px 0 0;
				cursor: pointer;
				font-size: 20px;
				line-height: 32px;
				margin: 0;
				&:after {
					position: absolute;
					right: 0;
					width: 14px;
					content: '';
					top: 9px;
					height: 14px;
					.svg('plus',@red);
				}
				&:before {
					display: none;
				}
				> span {
					color: @black;
				}
			});
			.resp(767px,{
				font-size: 16px;
				line-height: 24px;
				&:after {
					top: 5px;
				}
			});
		}
		.tab-content {
			.resp(999px,{
				display: none;
				p {
					& + br {
						display: block;
			            content: '';
			            padding-bottom: 16px;
					}
				}
			});
		}
	}

	a.text{
		
			text-decoration: underline;
		
	}
}

.tabs {
	margin: 0;
	padding: 0;
	list-style-type: none;
	.tabs-link {
		display: block;
		font-size: 16px;
		line-height: 24px;
		padding: 15px 0;
		position: relative;
		z-index: 2;
		&.is-active {
			font-weight: 500;
			&:after {
				position: absolute;
				left: -32px;
				top: 0;
				width: 9999px;
				height: 100%;
				content: '';
				z-index: 1;
				background: @bg;
			}
			> span {
				display: block;
				position: relative;
				z-index: 2;
			}
		}
	}
}
