.page-tabs {
	height: 56px;
	margin: -56px 0 -6px 0;
	position: relative;
	z-index: 10;
	.resp(1279px,{
		height: 36px;
		margin: -36px 0 -6px 0;
	});
	.resp(999px,{
		height: 48px;
		background: @bg;
		margin: 0;
	});
	ul {
		margin: 0;
		padding: 0 0 6px 0;
		list-style-type: none;
		display: flex;
		.resp(999px,{
			padding: 0;
		});
		> li {
			flex: 0 0 auto;
			padding-bottom: 20px;
			.resp(1279px,{
				padding-bottom: 12px;
			});
			.resp(999px,{
				padding: 0;
			});
			& + li {
				margin-left: 80px;
				.resp(1279px,{
					margin-left: 54px;
				});
				.resp(999px,{
					margin-left: 24px;
				});
				.resp(767px,{
					margin: 0;
				});
			}
			> a,
			> span {
				display: block;
				font-size: 24px;
				line-height: 36px;
				font-weight: bold;
				color: #FFF;
				letter-spacing: 0.05em;
				text-transform: uppercase;
				.resp(1279px,{
					font-size: 18px;
					line-height: 24px;
				});
				.resp(999px,{
					font-size: 16px;
					line-height: 24px;
					padding: 12px 16px;
					color: @black;
				});
				.resp(767px,{
					font-size: 12px;
				});
			}
			> a {
				&:hover {
					color: fade(#FFF,75%);
					.resp(999px,{
						color: @blackhover;
					});
				}
			}
			> span {
				cursor: default;
			}
		}
	}
	.container {
		.resp(999px,{
			padding: 0;
		});
	}
	.is-active {
		position: relative;
		&:after {
			position: absolute;
			left: 0;
			top: 100%;
			width: 100%;
			height: 6px;
			background: @red;
			content: '';
			.resp(1279px,{
				height: 4px;
			});
			.resp(999px,{
				top: auto;
				bottom: 0;
				height: 2px;
				width: 100%;
			});
		}
	}
}
