.products-new {
	overflow: hidden;
	.resp(767px,{
		background: none;
	});
	.container {
		padding-top: 140px;
		padding-bottom: 140px;
		position: relative;
		z-index: 1;
		.resp(1440px,{
			padding-top: 100px;
			padding-bottom: 100px;
		});
		.resp(1279px,{
			padding-top: 90px;
			padding-bottom: 90px;
		});
		.resp(999px,{
			padding-top: 80px;
			padding-bottom: 80px;
		});
		.resp(767px,{
			padding-top: 48px;
			padding-bottom: 48px;
		});
	}
	&:before {
		position: absolute;
		right: 100%;
		top: 0;
		width: 9999px;
		height: 100%;
		content: '';
		background: #FFF;
		margin-right: 147px;
		.resp(999px,{
			display: none;
		});
	}
	.products-new-row {
		display: flex;
		align-items: center;
		.resp(1023px,{
			display: block;
		});
		.products-new-container {
			position: relative;
			z-index: 1;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 32px;
			.resp(1919px,{
				flex: 1 1 auto;
				min-width: 0;
				max-width: none;
			});
			.resp(1279px,{
				margin-right: -32px;
			});
			.resp(1023px,{
				margin-right: 0;
			});
			.resp(767px,{
				display: block;
			});
		}
		.products-new-text {
			max-width: 500px;
			flex: 0 0 500px;
			margin-right: 68px;
			position: relative;
			z-index: 2;
			.resp(1919px,{
				max-width: 380px;
				flex: 0 0 380px;
			});
			.resp(1279px,{
				max-width: 256px;
				flex: 0 0 256px;
			});
			.resp(1023px,{
				max-width: none;
				margin-bottom: 40px;
			});
			.resp(767px,{
				margin-bottom: 24px;
			});
			> p {
				max-width: 424px;
				.resp(1023px,{
					max-width: 640px;
				});
				.resp(767px,{
					max-width: none;
					margin-top: 0;
				});
			}
			.block-header {
				margin-bottom: 40px;
				.resp(1279px,{
					margin-bottom: 24px;
				});
				.resp(767px,{
					margin-bottom: 8px;
				});
				> span {
					.resp(767px,{
						display: inline;
					});
				}
			}
			.carousel-nav {
				margin-top: 40px;
				.resp(767px,{
					display: none;
				});
			}
			.owl-dots {
				display: none;
				.resp(767px,{
					display: block;
				});
			}
		}
	}

}

.products-new-slide {
	width: 100%;
	aspect-ratio: 1.35;
	display: block;
	position: relative;
	&:not(:last-child) {
		margin-right: 32px;
		.resp(767px,{
			margin-right: 0;
			margin-bottom: 16px;
		});
	}
	.resp(1279px,{
		&:not(:last-child) {
			margin-right: 24px;
		}
	});
	.resp(767px,{
		width: 100%;
		height: 218px;
		overflow: hidden;
	});
	&:hover {
		.products-new-slide-hover {
			background: fade(@red,80%);
			> span {
				transition: all @hover;
				&:nth-child(1) {
					opacity: 0;
					pointer-events: none;
				}
				&:nth-child(2) {
					opacity: 1;
					pointer-events: auto;
				}
			}
			.resp(767px,{
				background: fade(@black,45%);
			});
		}
	}
	> img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
	.products-new-slide-hover {
		opacity: 1;
		transition: background @hover;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 54px 32px;
		background: fade(@black,45%);
		color: #FFF;
		font-size: 24px;
		line-height: 36px;
		font-weight: 500;
		> span {
			margin-right: 10px;
			position: absolute;
			transition: all @hover;
			&:nth-child(1) {
				opacity: 1;
				pointer-events: auto;
			}
			&:nth-child(2) {
				opacity: 0;
				pointer-events: none;
			}
		}
		.resp(1919px,{
			font-size: 16px;
			line-height: 28px;
		});
		.resp(1279px,{
			padding: 30px 24px;
		});
		.resp(767px,{
			background: fade(@black,45%);
			padding: 40px 24px;
			opacity: 1;
		});
		&:after {
			position: absolute;
			left: 32px;
			bottom: 40px;
			width: 48px;
			height: 48px;
			box-sizing: border-box;
			border: 1px solid #FFF;
			border-radius: 50%;
			content: '';
			.svg('rarr',#FFF);
			background-position: center center;
			background-size: 18px 11px;
			.resp(1279px,{
				width: 40px;
				height: 40px;
				left: 24px;
				bottom: 24px;
			});
			.resp(767px,{
				left: 24px;
				bottom: 24px;
			});
		}
		&:before {
			position: absolute;
			left: 32px;
			top: 40px;
			width: 48px;
			height: 2px;
			background: #FFF;
			content: '';
			.resp(1279px,{
				left: 24px;
				top: 22px;
				width: 32px;
			});
			.resp(767px,{
				height: 1px;
				left: 24px;
				top: 32px;
				width: 32px;
			});
		}
	}
}
