.footer-bottom {
	background-color: @black;
	
	border-top: 1px solid @line;
	padding: 32px 0;
	.resp(767px,{
		padding: 48px 0;
	});
	.footer-bottom-row {
		margin: 0 -20px;
		display: flex;
		align-items: center;
		.resp(1279px,{
			margin: 0;
			justify-content: space-between;
		});
		.resp(767px,{
			margin: 0;
			display: block;
		});
		.footer-bottom-col {
			flex: 0 0 33.3333%;
			max-width: 33.3333%;
			box-sizing: border-box;
			padding: 0 20px;
			.resp(1279px,{
				flex: 0 0 auto;
				padding: 0;
				max-width: none;
			});
		}
	}
	.footer-social {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		.resp(767px,{
			margin-top: 32px;
		});
		> li {
			flex: 0 0 auto;
			& + li {
				margin-left: 16px;
			}
			> a {
				width: 40px;
				height: 40px;
				box-sizing: border-box;
				display: block;
				border-radius: 50%;
				border: 1px solid @gray;
				transition: background @hover, border-color @hover;
				position: relative;
				&:hover {
					background: @red;
					border-color: @red;
				}
				&:before {
					position: absolute;
					left: 50%;
					top: 50%;
					content: '';
					transform: translate(-50%,-50%);
					transition: background @hover;
				}
			}
		}
		.fb {
			&:hover {
				&:before {
					.svg('fb',#FFF);
				}
			}
			&:before {
				width: 8px;
				height: 16px;
				.svg('fb',#FFF);
			}
		}
		.mail {
			&:hover {
				&:before {
					.svg('mail',#FFF);
				}
			}
			&:before {
				width: 18px;
				height: 14px;
				.svg('mail',#FFF);
			}
		}
		
		.yt {
			&:hover {
				&:before {
					.svg('yt',#FFF);
				}
			}
			&:before {
				width: 18px;
				height: 12px;
				.svg('yt',#FFF);
			}
		}

		.tg {
			&:hover {
				&:before {
					.svg('tg',#FFF);
				}
			}
			&:before {
				width: 18px;
				height: 14px;
				.svg('tg',#FFF);
			}
		}

		.vk {
			&:hover {
				&:before {
					.svg('vk',#FFF);
				}
			}
			&:before {
				width: 18px;
				height: 12px;
				.svg('vk',#FFF);
			}
		}


	}
	.logo {
		width: 189px;
		height: 48px;
		display: block;
		background: url('../img/logo-black.svg') no-repeat;
		background-size: 100% 100%;
		.resp(767px,{
			width: 95px;
			height: 24px;
			margin-bottom: 32px;
		});
	}
	.footer-mail {
		position: relative;
		padding-left: 44px;
		.resp(999px,{
			margin-top: 24px;
		});
		&:before {
			position: absolute;
			left: 0;
			top: 1px;
			content: '';
			width: 24px;
			height: 24px;
			.svg('mail',@red);
			background-size: 100% 100%;
			.resp(999px,{
				top: -1px;
			});
		}
	
	}
	.footer-phone {
		position: relative;
		padding-left: 44px;
		.resp(999px,{
			margin-top: 24px;
		});
		&:before {
			position: absolute;
			left: 0;
			top: 1px;
			content: '';
			width: 24px;
			height: 24px;
			background: url('../img/phone.svg') no-repeat;
			background-size: 100% 100%;
			.resp(999px,{
				top: -1px;
			});
		}
	
	}
	a{
		color: #FFF;
		&:hover {
			color: @gray;
		}
	}
}

.footer-nav {
	background-color: @black;
	color: white;
	border-top: 1px solid @line;
	padding: 66px 0;
	overflow: hidden;
	.resp(1279px,{
		padding-bottom: 44px;
	});
	.resp(999px,{
		padding-top: 64px;
	});
	.resp(767px,{
		display: none;
	});
	.container {
		column-count: 3;
		column-gap: 40px;
		/*.resp(1279px,{
			column-count: 2;
		});*/
	}
	a{
		color: #FFF;
		&:hover {
			color: @gray;
		}
	}
	
	.footer-nav-box {
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid-column;
		.resp(1279px,{
			margin-bottom: 32px;
		});
		.footer-nav-box-content {
			padding: 24px 0;
			.resp(1279px,{
				padding: 16px 0;
			});
			> ul {
				margin: 0;
				padding: 0;
				list-style-type: none;
				max-width: 320px;
				> li {
					& + li {
						margin-top: 8px;
					}
				}
			}
		}
	}
}
