.intro-box-icon {
	margin: 0;
	margin-top: 80px;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	gap: 40px 80px;

	.resp(1279px,{
		margin-top: 64px;
		gap: 20px 24px;
	});

	.resp(479px,{
		margin-top: 24px;
	});

	&__item {
		display: flex;
		align-items: center;
		column-gap: 6px;

		img {
			width: 60px;
			height: 60px;
			flex-shrink: 0;
		}

		span {
		}
	}
}
