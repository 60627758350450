.materials-grid {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 33px;

	.resp(1023px,{
		grid-template-columns: repeat(3, minmax(0, 1fr));
	});

	.resp(767px,{
		grid-template-columns: repeat(1, minmax(0, 1fr));
		gap: 16px;
	});
}
