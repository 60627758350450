.i-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.resp(1279px,{
		margin: 0 -20px;
	});
	.resp(767px,{
		margin: 0;
		display: block;
	});
	&.i-box-reverse {
		.i-box-content {
			order: 2;
		}
		.i-box-image {
			order: 1;
			margin: 0 40px 0 0;
			.resp(1279px,{
				margin: 0;
			});
			.resp(767px,{
				margin-bottom: 24px;
			});
			&:before {
				margin-right: -1px;
				left: auto;
				right: 100%;
			}
			&:after {
				right: auto;
				left: -70px;
				.resp(767px,{
					left: 0;
				});
			}
		}
		.i-box-mark-list {
			list-style-type: none;
			margin: 0;
			padding-left: 27px;

			li {
				position: relative;
				line-height: 28px;
				&:not(:last-child) {
					margin-bottom: 8px;
				}

				&::before {
					position: absolute;
					top: 6px;
					left: 0;
					content: "";
					width: 15px;
					height: 13px;
					background-image: url('../img/check-red.svg');
					background-size: 100% 100%;
					transform: translateX(-27px);
				}
			}
		}
	}
	&.i-box-index {
		.i-box-image {
			flex: 0 0 665px;
			max-width: 665px;
			.resp(1279px,{
				flex: 0 0 50%;
				max-width: 50%;
				padding: 0 20px;
				box-sizing: border-box;
				margin: 0;
			});
			.resp(767px,{
				max-width: none;
				padding: 0;
				display: none;
			});
			&:before {
				display: none;
			}
			&:after {
				display: none;
			}
		}
		.i-box-content {
			max-width: 500px;
			.resp(1279px,{
				flex: 0 0 50%;
				max-width: 50%;
				padding: 0 20px;
				box-sizing: border-box;
				margin-bottom: 0px;
			});
			.resp(767px,{
				max-width: none;
				padding: 0;
				margin-bottom: 0;
			});
			.button {
				.resp(767px,{
					display: inline-block;
				});
			}

		}
		& + .button {
			margin-top: 8px;
			display: none;
			.resp(767px,{
				display: inline-block;
			});
		}
	}
	& + .card-carousel {
		margin-top: 100px;
		.resp(1440px,{
			margin-top: 90px;
		});
		.resp(1279px,{
			margin-top: 80px;
		});
		.resp(999px,{
			margin-top: 48px;
		});
		.resp(767px,{
			margin-top: 32px;
		});
		& + .button {
			margin-top: 32px;
			display: none;
			.resp(767px,{
				display: inline-block;
			});
		}
	}
	.i-box-content {
		flex: 1 1 auto;
		min-width: 0;
		max-width: 540px;
		.resp(1279px,{
			flex: 0 0 50%;
			max-width: 50%;
			padding: 0 20px;
			box-sizing: border-box;
			margin-bottom: 40px;
		});
		.resp(767px,{
			max-width: none;
			padding: 0;
		});
		> p {
			.resp(767px,{
				margin: 16px 0;
				.margin;
			});
		}
		> span {
			font-size: 24px;
			line-height: 36px;
			display: block;
			.resp(1279px,{
				font-size: 20px;
				line-height: 30px;
			});
			.resp(767px,{
				font-size: 16px;
				line-height: 26px;
			});
		}
		.button {
			margin-top: 20px;
			.resp(999px,{
				margin: 0;
			});
			.resp(767px,{
				display: none;
			});
		}
		.block-header {
			margin-bottom: 40px;
			.resp(999px,{
				margin-bottom: 24px;
			});
			& + span {
				display: block;
				font-size: 24px;
				line-height: 36px;
				margin-bottom: 24px;
				.resp(1279px,{
					font-size: 20px;
					line-height: 32px;
					margin-bottom: 16px;
					& + p {
						margin-top: 0;
					}
				});
				.resp(999px,{
					font-size: 18px;
					line-height: 30px;
				});
				.resp(767px,{
					font-size: 16px;
					line-height: 28px;
				});
			}
		}
		.i-box-image {
			display: none;
			margin-bottom: 24px;
			.resp(767px,{
				display: block;
			});
		}
	}
	.i-box-image {
		flex: 0 0 600px;
		max-width: 600px;
		position: relative;
		margin-left: 40px;
		.resp(1279px,{
			flex: 0 0 50%;
			max-width: 50%;
			padding: 0 20px;
			box-sizing: border-box;
			margin: 0;
		});
		.resp(767px,{
			max-width: none;
			padding: 0;
			display: none;
		});
		&.no-decore {
			&:before,
			&:after {
				display: none;
			}
		}
		&:before {
			position: absolute;
			margin-left: -1px;
			left: 100%;
			top: 50%;
			width: 9999px;
			height: 50%;
			background: @bg;
			content: '';
			z-index: 1;
			.resp(767px,{
				display: none;
			});
		}
		&:after {
			position: absolute;
			right: -70px;
			top: 50%;
			width: 140px;
			height: 70px;
			background: url('../img/lines.svg') no-repeat;
			background-size: 100% 100%;
			content: '';
			z-index: 3;
			.resp(767px,{
				width: 57px;
				height: 32px;
				right: auto;
				left: 0;
				top: auto;
				bottom: 32px;
			});
		}
		> img {
			display: block;
			width: 100%;
			position: relative;
			z-index: 2;
		}
	}
}
