.material-item {
	box-sizing: border-box;
	position: relative;
	min-height: 150px;
	padding: 24px;
	border: 1px solid #bdbdbd;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	transition: border-color @hover, color @hover, background-color @hover;
	overflow: hidden;
	row-gap: 8px;

	.resp(767px,{
		min-height: 0;
	});

	&:hover {
		border: 0.5px solid @red;

		.more-link {
			&::before {
				transform: translateX(8px) translateY(-50%);
			}
		}
	}

	.more-link {
		padding-right: 26px;

		.resp(767px,{
			font-size: 14px;
			line-height: 18px;
		});

		&::before {
			.resp(767px,{
				width: 18px;
				height: 11px;
			});
		}
	}

	&__name {
		line-height: 24px;
	}

	& > svg {
		fill: @red;
		width: 124px;
		height: 124px;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(19px, -50%);
		transition: fill @hover;

		.resp(1199px,{
			transform: translate(49px, -50%);
		});
	}

	&--icon {
		padding: 24px 16px;

		.resp(767px,{
			min-height: 150px;
		});

		&:hover {
			background-color: @red;
			color: #fff;

			.more-link {
				&::before {
					.svg('rarr', #fff);
				}
			}

			& > svg {
				fill: #fff;
			}
		}

		.material-item__name {
			max-width: 123px;
		}
	}
}
