.migration-conditions {
    &__text {
      max-width: 600px;
      margin-top: 60px;
      margin-bottom: 40px;
  
      .resp(999px,{
        margin-top: 30px;
        margin-bottom: 20px;
      });
    }
  }
  