.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    margin-bottom: 72px;
    padding: 0;
    .resp(1440px,{
      margin-bottom: 72px;
    });
    .resp(1279px,{
      margin-bottom: 50px;
    });
    .resp(999px,{
      margin-bottom: 40px;
    });
  
    &__item {
      position: relative;
      display: flex;
      align-items: center;
  
      &:not(:last-child) {
        &::after {
          width: 28px;
          height: 1px;
          background-color: @black;
          content: "";
          margin: 0 8px;
        }
      }
  
      &:last-child {
        .breadcrumbs__link {
          color: @red;
          cursor: default;
        }
      }
    }
  
    &__link {
      max-width: 150px;
      font-size: 12px;
      line-height: 20px;
      transition: color @hover;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  
      &:hover {
        color: @red;
      }
    } 
  }
  