.info-section {
	overflow: hidden;
	.resp(1680px,{
		overflow: visible;
	});
	.container {
		max-width: 1708px;
		display: flex;
		align-items: center;
		.resp(767px,{
			display: block;
		});
	}
	.info-section-image {
		flex: 0 1 782px;
		max-width: 782px;
		position: relative;
		.resp(1680px,{
			flex: 0 0 50%;
			max-width: 50%;
		});
		.resp(767px,{
			max-width: none;
		});
		&:after {
			position: absolute;
			left: -70px;
			top: 72px;
			width: 140px;
			height: 70px;
			background: url('../img/lines.svg') no-repeat;
			background-size: 100% 100%;
			content: '';
			z-index: 10;
			.resp(1680px,{
				background-image: url('../img/lines-vertical.svg');
				top: -70px;
				left: 72px;
				width: 70px;
				height: 140px;
			});
			.resp(1279px,{
				width: 32px;
				height: 72px;
				left: 24px;
				top: -36px;
			});
			.resp(767px,{
				left: auto;
				right: 24px;
			});
		}
		> img {
			display: block;
		}
	}
	.info-section-content {
		flex: 0 1 670px;
		max-width: 670px;
		margin-left: 40px;
		display: flex;
		justify-content: flex-end;
		.resp(1279px,{
			flex: 0 0 50%;
			max-width: 50%;
			padding-left: 80px;
			margin: 0;
			box-sizing: border-box;
		});
		.resp(999px,{
			padding-left: 40px;
		});
		.resp(767px,{
			max-width: none;
			padding: 0;
			margin-top: 32px;
			display: block;
		});
		.block-header {
			margin-bottom: 48px;
			.resp(1279px,{
				margin-bottom: 40px;
			});
			.resp(767px,{
				margin-bottom: 24px;
			});
		}
		.info-section-text {
			flex: 1 1 auto;
			max-width: 538px;
			.resp(767px,{
				max-width: none;
			});
		}
	}
}

.info-section-base {
	.container {
		max-width: 1370px;

		.resp(767px,{
			display: flex;
      flex-direction: column-reverse;
			row-gap: 24px;
    });
	}

	.info-section-image:after {
		background: url(../img/lines-black.svg) no-repeat;
		background-size: 100% 100%;

		.resp(1279px,{
      width: 75px;
      height: 48px;
      left: -30px;
      top: 53px;
    });
	}

	.info-section-content {
		.resp(1279px,{
      padding-left: 32px;
    });

		.resp(767px,{
      padding-left: 0px;
    });
	}
}

.info-section-rtl {
	.container {
		flex-direction: row-reverse;

		.resp(767px,{
      flex-direction: column-reverse;
    });
	}

	.info-section-content {
		margin-right: 40px;
		justify-content: flex-start;

		.resp(767px,{
      margin-right: 0;
			margin-top: 0;
    });
	}
}

.info-section-event {
	flex: 1 1 704px;
	max-width: 704px;
	min-width: 452px;
	margin-left: 40px;
	padding: 32px 32px 48px 32px;
	background-color: #f6f6f6;
	display: flex;
	flex-direction: column;
	row-gap: 24px;
	position: relative;

	.resp(767px,{
		padding: 16px 16px 24px 16px;
		margin-left: 0;
		flex: initial;
	});

	&::after {
		content: '';
		position: absolute;
		right: 32px;
		bottom: 48px;
		width: 60px;
		height: 60px;
		background-image: url('./../img/quotes.svg');

		.resp(767px,{
			display: none;
		});
	}

	&__preview {
		width: 100%;
		height: 200px;
		overflow: hidden;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__content {
	}

	&__bottom {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		row-gap: 4px;
	}

	&__name {
		font-size: 20px;
		font-weight: 700;
	}
}

