.ul-style {
	margin: 0;
	padding: 0;
	list-style-type: none;

	& > li {
		position: relative;
		padding-left: 18px;

		&::before {
			position: absolute;
			left: 0;
			top: 3px;
			width: 2px;
			height: 24px;
			content: '';
			background: #ff3f23;
		}

		& + li {
			margin-top: 8px;
		}
	}
}
