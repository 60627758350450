.contact {
	flex: 0 0 25%;
	max-width: 25%;
	box-sizing: border-box;
	padding: 38px 16px;
	.resp(999px,{
		flex: 0 0 50%;
		max-width: 50%;
	});
	.resp(767px,{
		max-width: none;
		padding: 0;
		& + .contact {
			margin-top: 40px;
		}
	});
	> img {
		width: 100%;
		display: block;
		margin-bottom: 48px;
		.resp(1279px,{
			margin-bottom: 24px;
		});
	}
	a {
		text-decoration: underline;
		//font-weight: bold;
	}
	.contact-address {
		position: relative;
		padding-left: 44px;
		&:before {
			position: absolute;
			left: 1px;
			top: 1px;
			content: '';
			width: 22px;
			height: 31px;
			background: url('../img/location.svg') no-repeat;
			background-size: 100% 100%;
			.resp(999px,{
				top: -3px;
			});
		}
	}
	.contact-city {
		font-size: 24px;
		line-height: 36px;
		font-weight: bold;
		margin-bottom: 8px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		.resp(1279px,{
			font-size: 20px;
			line-height: 32px;
		});
	}
	.contact-phone {
		margin-top: 20px;
		position: relative;
		padding-left: 44px;
		.resp(999px,{
			margin-top: 24px;
		});
		&:before {
			position: absolute;
			left: 0;
			top: 3px;
			content: '';
			width: 24px;
			height: 24px;
			background: url('../img/phone.svg') no-repeat;
			background-size: 100% 100%;
			.resp(999px,{
				top: -1px;
			});
		}
		a {
			text-decoration: none;
		}
	}
	.contact-email {
		margin-top: 20px;
		position: relative;
		padding-left: 44px;
		font-weight: 100;
		.resp(999px,{
			margin-top: 24px;
		});
		&:before {
			position: absolute;
			left: 0;
			top: 3px;
			content: '';
			width: 24px;
			height: 24px;
			background: url('../img/mail-red.svg') no-repeat;
			background-size: 100% 100%;
			.resp(999px,{
				top: -1px;
			});
		}
		a {
			text-decoration: none;
		}
	}
	.contact-type {
		padding-bottom: 32px;
		margin-bottom: 32px;
		position: relative;
		.resp(999px,{
			padding-bottom: 24px;
		});
		&:after {
			position: absolute;
			left: 0;
			bottom: -1px;
			width: 48px;
			height: 2px;
			content: '';
			background: @red;
		}
	}

	.block-header.recv {
		margin-bottom: 32px;
	}
}

.contact-grid {
	display: flex;
	flex-wrap: wrap;
	margin: -38px -16px;
	.resp(767px,{
		margin: 0;
		display: block;
	});
	p {
		max-width: 860px;
	}
}

.no-decore {
	list-style-type:none;
}

.contact-product {
	display: flex;
	flex-wrap: nowrap;
	margin: -38px -16px;
	.resp(767px,{
		margin: 0;
		display: block;
	});
	p {
		max-width: 860px;
	}
	
}

.contact-product-card {
	flex: 0 0 33,3%;
	max-width: 33,3%;
	box-sizing: border-box;
	padding: 38px 16px;
	.resp(999px,{
		flex: 0 0 50%;
		max-width: 50%;
	});
	.resp(767px,{
		max-width: none;
		padding: 0;
		& + .contact {
			margin-top: 40px;
		}
	});
}