.more-link {
	position: relative;
	font-weight: 500;
	display: inline-block;
	vertical-align: top;
	max-width: 100%;
	padding-right: 26px;
	.resp(767px,{
		font-size: 14px;
		padding-right: 21px;
	});
	.resp(1280px,{
		padding-right: 36px;
	});
	.resp(1024px,{
		padding-right: 52px;
	});
	&:hover {
		&:before {
			.svg('rarr',@blackhover);
			transform: translateX(8px) translateY(-50%);
			.resp(767px,{
				transform: translateX(4px) translateY(-50%);
			});
		}
	}
	&:before {
		position: absolute;
		right: 0;
		top: 50%;
		width: 18px;
		height: 11px;
		content: '';
		transform: translateY(-50%);
		.svg('rarr',@black);
		transition: background @hover, transform @hover;
		.resp(767px,{
			width: 16px;
			height: 8px;
		});
	}
}

.intro-more-link {
	font-weight: 700;
	font-size: 16px;
	color: #FFF;
	text-transform: uppercase;
	position: relative;
	display: inline-block;
	vertical-align: top;
	max-width: 100%;
	margin-left: 50px;
	margin-bottom: 48px;
	.resp(767px,{
		font-size: 14px;
		padding-right: 21px;
		margin-left: 32px;
	});
	.resp(1280px,{
		padding-right: 36px;
	});
	.resp(1024px,{
		padding-right: 52px;

	});
	&:hover {
		color: #FFF;
		&:before {
			.svg('line',#FFF);
			transform: translateX(-8px) translateY(-50%);
			.resp(767px,{
				transform: translateX(-4px) translateY(-50%);
			});
		}
	}
	&:before {
		position: absolute;
		top: 50%;
		width: 35px;
		height: 20px;
		content: '';
		left: -50px;
		transform: translateY(-50%);
		.svg('line',#FFF);
		transition: background @hover, transform @hover;
		.resp(767px,{
			width: 24px;
			height: 16px;
			left: -32px;
		});
	}



}