.section-zero-trust {
	padding: 140px 0;
	background-color: #f6f6f6;
	margin-top: 88px;
	position: relative;

	.resp(1279px,{
		margin-top: 72px;
		padding: 88px 0;
	});

	.resp(479px,{
		padding: 48px 0;
	});

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 140px;
		background-color: #fff;

		.resp(1629px,{
			display: none;
		});
	}

	&__inner {
		display: flex;
		align-items: center;
		column-gap: 72px;

		.resp(1279px,{
			flex-direction: column;
			align-items: flex-start;
			row-gap: 32px;
		});
	}

	&__left {
		flex-shrink: 0;
		width: 430px;

		.resp(1279px,{
			width: 100%;
		});

		& > p {
			.resp(1279px,{
				margin: 0;
			});
		}
	}

	&__title {
		margin-bottom: 40px;

		.resp(1279px,{
			margin-bottom: 16px;
		});
	}

	&__right {
		flex: 1 1 auto;
	}

	&__item {
		display: flex;
		min-height: 144px;
		border-left: 4px solid #ff3f23;
		background-color: #fff;

		.resp(1279px,{
			min-height: 116px;
		});

		.resp(479px,{
			flex-direction: column;
			border-left: none;
		});

		& + .section-zero-trust__item {
			margin-top: 32px;

			.resp(1279px,{
				margin-top: 16px;
			});
		}

		&:nth-child(even) {
			flex-direction: row-reverse;
			border-left: none;
			border-right: 4px solid #ff3f23;

			.resp(479px,{
				flex-direction: column;
				border-right: none;
			});
		}

		& > img {
			flex-shrink: 0;
			width: 144px;
			object-fit: cover;
			object-position: center;

			.resp(479px,{
				width: 100%;
				height: 144px;
				border-left: 4px solid @red;
			});
		}

		& > p {
			padding: 24px 32px;
			margin: 0;

			.resp(1279px,{
				padding: 16px 24px;
			});

			.resp(479px,{
				padding: 16px;				
			});

			span {
				display: inline-block;
				font-size: 16px;
				font-weight: 700;
				text-transform: uppercase;
				margin-bottom: 8px;
			}

		}
	}
}
