.product {
	.info-block {
		margin-bottom: 0px;
	}
	
	.resp(767px,{
		display: flex;
		flex-direction: column;
		.info-block {
			width: 100%;
			flex: 0 0 auto;
			order: 1;
			margin: 0;
			padding-bottom: 0px;
			&:after,
			&:before {
				display: none;
			}
		}
		.section {
			width: 100%;
			flex: 0 0 auto;
			order: 2;
			padding-bottom: 0;

		}
	});
}
