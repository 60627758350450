header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 100;
	transition: background @hover, box-shadow @hover, border-color @hover;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	.resp(1279px,{
		background: #FFF;
	});
	.blog-body & {
		background: #fff;
		border-color: @line;
		.logo {
			background-image: url('../img/logo-black.svg');
		}
		.nav {
			> ul {
				> li {
					> a {
						color: @black;
						&:hover {
							color: @blackhover;
						}
						&:after {
							background-color: @black;
						}
					}
				}
			}
		}
	}
	.is-scrolled & {
		background: #ffffff;
		box-shadow: 0px 15px 40px rgba(51, 51, 51, 0.05);
		border-color: transparent;
	}
	.container {
		height: 90px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.resp(1279px,{
			height: 68px;
		});
		.resp(999px,{
			height: 56px;
			position: relative;
		});
	}
	.logo {
		width: 189px;
		height: 48px;
		flex: 0 0 auto;
		display: block;
		background: url('../img/logo-white.svg') no-repeat;
		background-size: 100% 100%;
		transition: background @hover;
		.resp(1279px,{
			position: relative;
			z-index: 30;
			background-image: url('../img/logo-black.svg');
			width: 124px;
			height: 32px;
		});
		.resp(999px,{
			width: 94px;
			height: 24px;
		});
		.is-scrolled & {
			background-image: url('../img/logo-black.svg');
		}
	}
	.nav {
		flex: 0 0 auto;
		.resp(1279px,{
			display: none;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			padding-top: 68px;
			border-color: #000;
			background: #FFF;
			z-index: 20;
			align-items: stretch;
			.is-locked & {
				display: flex;
			}
		});
		.resp(999px,{
			padding-top: 56px;
		});
		> ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
			display: flex;
			.resp(1279px,{
				background: #FFF;
				flex: 1 1 100%;
				max-width: 100%;
				overflow: auto;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 14px 32px 32px 32px;
				box-sizing: border-box;
			});
			.resp(999px,{
				padding: 16px;
			});
			> li {
				flex: 0 0 auto;
				& + li {
					margin-left: 32px;
					.resp(1279px,{
						margin: 16px 0 0 0;
					});
					.resp(999px,{
						margin: 12px 0 0 0;
					});
				}
				> a {
					display: block;
					font-size: 16px;
					line-height: 20px;
					letter-spacing: 0.2px;
					color: #fff;
					position: relative;
					.resp(1279px,{
						color: @black;
						font-size: 14px;
						line-height: 18px;
						font-weight: 700;
						letter-spacing: normal;
					});
					.resp(999px,{
						font-weight: 500;
					});
					.is-scrolled & {
						color: @black;
					}
					&:hover {
						color: fade(#fff, 80%);
						.resp(1279px,{
							color: @blackhover;
						});
						.is-scrolled & {
							color: @blackhover;
						}
						&:before {
							background-color: fade(@red, 80%);
						}
						&:after {
							opacity: 0;
						}
					}
					&:after {
						position: absolute;
						left: 0;
						right: 0;
						bottom: -36px;
						content: '';
						width: 100%;
						height: 2px;
						transition: all @hover;
						opacity: 0;
						background-color: #fff;
						pointer-events: none;
						.resp(1279px,{
							display: none;
						});
						.is-scrolled & {
							background-color: @black;
						}
					}
				}
			}
		}
		.is-active {
			font-weight: 500;
			.resp(1279px,{
				color: @red !important;
			});
			&:hover {
				&:after {
					opacity: 1;
				}
			}
			&:after {
				opacity: 1;
			}
		}
		.accent {
			&:before {
				content: '';
				width: 16px;
				height: 16px;
				background-color: @red;
				display: inline-block;
				margin-right: 8px;
				margin-bottom: -2px;
				.resp(1279px,{
					width: 12px;
					height: 12px;
					margin-bottom: -1px;
				});
			}
		}
	}
	.nav-button {
		margin: 0;
		padding: 0;
		outline: 0;
		cursor: pointer;
		border: 0;
		width: 18px;
		height: 12px;
		background: none;
		position: absolute;
		right: 32px;
		top: 50%;
		-webkit-border-radius: 0;
		-webkit-appearance: none;
		transform: translateY(-50%);
		display: none;
		z-index: 30;
		.is-locked & {
			> span {
				&:nth-child(1) {
					transform: translate(-50%, -50%) rotate(45deg);
					left: 50%;
					top: 50%;
				}
				&:nth-child(2) {
					display: none;
				}
				&:nth-child(3) {
					transform: translate(-50%, -50%) rotate(-45deg);
					left: 50%;
					top: 50%;
					bottom: auto;
				}
			}
		}
		.resp(1279px,{
			display: block;
		});
		.resp(999px,{
			right: 16px;
		});
		> span {
			width: 100%;
			height: 2px;
			background: @black;
			position: absolute;
			left: 0;
			&:nth-child(1) {
				top: 0;
			}
			&:nth-child(2) {
				top: 50%;
				margin-top: -1px;
			}
			&:nth-child(3) {
				bottom: 0;
			}
		}
	}
}
