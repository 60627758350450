.product-new {
	background-color: #f6f6f6;
	display: flex;
	flex-direction: column;

	&__content {
		flex: 1 1 auto;
		padding: 40px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.resp(1199px,{
			padding: 24px 16px;
		});
	}

	&__preview {
		position: relative;

		&::after {
			content: '';
			display: block;
			padding-top: 64%;
		}

		img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__title {
		margin-bottom: 8px;
		line-height: 24px;

		span {
			color: @red;
		}
	}

	&__descr {
		margin-bottom: 24px;
	}

	&__more {
		margin-top: auto;

		.resp(767px,{
			font-size: 14px;
			line-height: 18px;
		});

		&::before {
			.resp(767px,{
				width: 18px;
				height: 11px;
			});
		}
	}

	&__list {
		margin: 0;
		list-style-type: none;
		padding: 40px;
		display: flex;
		flex-direction: column;
		row-gap: 8px;
		border-top: 2px solid #fff;
		margin-top: auto;

		.resp(1199px, {
			padding: 24px 16px;
		});
	}

	&__link {
		padding: 8px 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		column-gap: 24px;
		border: 1px solid #bdbdbd;
		transition: border-color @hover, background-color @hover;

		.resp(1023px,{
			padding: 8px 16px;
			column-gap: 0;
		});

		&:hover {
			background-color: @red;
			border-color: @red;

			span {
				color: #fff;
			}

			svg {
				fill: #fff;
			}
		}

		span {
			font-size: 16px;
			line-height: 24px;
			transition: color @hover;

			.resp(1199px,{
				font-size: 14px;
				line-height: 18px;
			});
		}

		svg {
			width: 55px;
			height: 55px;
			flex-shrink: 0;
			transition: fill @hover;
		}
	}
}
