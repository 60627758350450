.show-more {
	&.is-opened {
		.show-more-hidden {
			display: block;
		}
		.show-more-link,
		.show-more-link-two {
			&:before {
				transform: translateY(-50%) rotate(180deg);
			}
			> span {
				&:first-child {
					display: none;
				}
				&:last-child {
					display: block;
				}
			}
		}
	}
	.show-more-hidden {
		margin-bottom: 23px;
		display: none;
		.resp(767px,{
			margin-bottom: 16px;
		});
	}
	.show-more-link,
	.show-more-link-two {
		position: relative;
		font-weight: 500;
		display: inline-block;
		vertical-align: top;
		max-width: 100%;
		padding-right: 26px;
		.resp(767px,{
			font-size: 12px;
		});
		&:hover {
			&:before {
				.svg('down',@blackhover);
			}
		}
		&:before {
			position: absolute;
			right: 0;
			top: 50%;
			width: 10px;
			height: 6px;
			content: '';
			transform: translateY(-50%);
			.svg('down',@black);
			transition: background @hover, transform @hover;
		}
		> span {
			&:first-child {
				display: block;
			}
			&:last-child {
				display: none;
			}
		}
	}
}
