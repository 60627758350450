.advantages-new {
	&__grid {
		display: grid;
		grid-template-columns: repeat(4, minmax(0, 1fr));
		column-gap: 64px;
		row-gap: 64px;

		.resp(1199px,{
			column-gap: 32px;
		});

		.resp(1023px,{
			row-gap: 40px;
		});

		.resp(767px,{
			grid-template-columns: repeat(2, minmax(0, 1fr));
		});
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		row-gap: 18px;
	}

	&__img {
		width: 88px;
		height: 88px;

		.resp(1199px,{
			width: 80px;
			height: 80px;
		});
	}

	&__text {
		font-size: 24px;
		font-weight: 500;
		line-height: 36px;
		margin-bottom: 0;

		.resp(1199px,{
			font-size: 20px;
			line-height: 28px;
		});

		.resp(1023px,{
			font-size: 16px;
			line-height: 24px;
		});

		span {
			color: @red;
		}
	}
}
