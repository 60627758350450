.section-arguments {
	&__title {
		max-width: 768px;

		.resp(1279px,{
			max-width: 100%;
		});
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
		gap: 32px;
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		row-gap: 16px;

		img {
			width: 64px;
			height: 64px;
			flex-shrink: 0;

			.resp(767px,{
				width: 48px;
				height: 48px;
			});
		}
	}
}