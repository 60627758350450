.materials {
	&__title {
		margin-bottom: 56px;

		.resp(1023px,{
			margin-bottom: 40px;
		});

		.resp(767px,{
			line-height: 24px;
			margin-bottom: 32px;
		});
	}
}
