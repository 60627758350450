.intro-line {
	position: relative;
	z-index: 1;
	background-color: #ff3f23cc;
	display: flex;
	align-items: center;
	column-gap: 80px;
	padding: 36px 0;

	.resp(1440px,{
		column-gap: 40px;
	});

	.resp(767px,{
		flex-direction: column;
		align-items: flex-start;
		row-gap: 24px;
	});

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #ff3f23cc;
	}

	&::before {
		left: 0;
		transform: translateX(-100%);
	}

	&::after {
		right: 0;
		transform: translateX(100%);
	}

	&__text {
		max-width: 310px;
	}

	&__wrap {
		display: flex;
		align-items: center;
		column-gap: 56px;

		.resp(767px,{
			flex-direction: column;
			align-items: flex-start;
			row-gap: 8px;
		});
	}

	&__item {
		display: flex;
		align-items: center;
		column-gap: 10px;

		& > img {
			width: 60px;
			height: 60px;
		}

		& > span {
		}
	}
}
