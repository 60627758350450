.info-block-conversion {
	position: relative;
	z-index: 1;
	.section;
	margin-bottom: 140px;
	.resp(1440px,{
		margin-bottom: 100px;
	});
	.resp(1279px,{
	margin-bottom: 90px;
	});
	.resp(999px,{
		margin-bottom: 80px;
	});
	.resp(767px,{
		margin-bottom: 48px;
	});
	&:after {
		width: 1662px;
		position: absolute;
		left: 50%;
		top: 0;
		height: 100%;
		content: '';
		z-index: 2;
		transform: translateX(-50%);
	}
	&:before {
		width: 9999px;
		position: absolute;
		left: 50%;
		top: 0;
		height: 100%;
		content: '';
		z-index: 1;
	}
	.container {
		position: relative;
		z-index: 3;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.resp(1200px,{
			padding: 0;
		});
		.resp(999px,{
			display: block;
			padding: 0 16px;
		});
	}
	.info-block-text {
		flex: 1 1 600px;
		max-width: 600px;
		margin-right: 40px;
		.resp(1200px,{
			flex: 0 0 50%;
			max-width: 50%;
			padding: 0 32px;
			box-sizing: border-box;
			margin: 0;
		});
		.resp(999px,{
			max-width: none;
			padding: 0;
			margin-bottom: 40px;
		});
		.resp(767px,{
			margin: 0;
		});
		> p {
			& + .button {
				margin-top: 20px;
				.resp(999px,{
					margin-top: 8px;
				});
				.resp(767px,{
					margin: 0;
				});
			}
		}
		.block-header {
			margin-bottom: 40px;
			.resp(767px,{
				margin-bottom: 24px;
			});
		}
	}
	.info-block-media {
		flex: 0 0 665px;
		max-width: 665px;
		.resp(1200px,{
			flex: 0 0 50%;
			max-width: 50%;
			padding: 0 32px;
			box-sizing: border-box;
		});
		.resp(999px,{
			max-width: none;
			padding: 0;
		});
		.info-block-lines {
			position: relative;
			&:after {
				position: absolute;
				right: -70px;
				bottom: 72px;
				width: 140px;
				height: 70px;
				background: url('../img/lines.svg') no-repeat;
				background-size: 100% 100%;
				content: '';
				z-index: 10;
				.resp(999px,{
					width: 35px;
					height: 70px;
					right: 36px;
					bottom: auto;
					top: -35px;
					background-image: url('../img/lines-vertical.svg');
				});
				.resp(767px,{
					display: none;
				});
			}
			> img {
				display: block;
				width: 100%;
			}
		}
		.video {
			&.is-loaded {
				&:after {
					display: none;
				}
			}
		}
	}
	.intro-bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		object-fit: cover;
	}    

	.info-block-width-text {
		flex: 0 1 950px;
		max-width: 950px;
		margin-right: 40px;
		.resp(1200px,{
			flex: 0 0 70%;
			max-width: 70%;
			padding: 0 32px;
			box-sizing: border-box;
			margin: 0;
		});
		.resp(999px,{
			max-width: none;
			padding: 0;
			margin-bottom: 40px;
		});
		.resp(767px,{
			margin: 0;
		});
		> p {
			& + .button {
				margin-top: 20px;
				.resp(999px,{
					margin-top: 8px;
				});
				.resp(767px,{
					margin: 0;
				});
			}
		}
		.block-header {
			margin-bottom: 40px;
			.resp(767px,{
				margin-bottom: 24px;
			});
		}
	}

	
	

}

.info-block-conversion.index{
	.section;
	//margin-bottom: 0px;
	margin-top: 140px;
	.resp(1440px,{
		margin-top: 100px;
	});
	.resp(1279px,{
		margin-top: 90px;
	});
	.resp(999px,{
		margin-top: 80px;
	});
	.resp(767px,{
		margin-top: 48px;
	});
}

.info-block-conversion.invert {
		.container {
			justify-content: flex-end;
		}
		img {
			transform: scale(-1, 1);
		}
		.resp(767px,{
			.container {
				justify-content: space-between;
			}
			img {
				transform: none;
			}
		});
}
	