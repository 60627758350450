.intro-conf {
	width: 100%;
	max-width: 580px;
	color: @white;
	margin-top: 90px;
	padding-left: 58px;
	// font-family: Manrope;

	.resp(1439px,{
		margin-top: 125px;
	});

	.resp(1024px,{
		max-width: 324px;
		margin-top: 0;
	});

	.resp(767px,{
		padding-left: 0;
	});

	&__title {
		font-size: 96px;
		font-weight: 800;
		line-height: 96%;
		letter-spacing: -3.84px;

		.resp(1439px,{
			font-size: 76px;
		});

		.resp(1024px,{
			font-size: 48px;
			letter-spacing: -0.84px;
		});
	}

	&__info {
		font-size: 18px;
		font-weight: 500;
		line-height: 130%;
		margin-top: 22px;

		.resp(1024px,{
			font-size: 16px;
			margin-top: 8px;
		});
	}

	&__description {
		font-size: 24px;
		font-weight: 500;
		line-height: 120%;
		margin-top: 40px;

		.resp(1024px,{
			font-size: 18px;
			margin-top: 20px;
		});

		.resp(767px,{
			display: none;
		});
	}

	&__btn {
		display: inline-block;
		border-radius: 55px;
		background: #ff0030;
		border: 1px solid #ff0030;
		color: @white;
		font-size: 24px;
		font-weight: 800;
		line-height: 120%;
		padding: 20px 40px;
		margin-top: 80px;
		transition: background-color 0.2s ease-in-out;

		.resp(1439px,{
			margin-top: 40px;
		});

		.resp(1024px,{
			font-size: 18px;
			margin-top: 40px;
			padding: 15px 44px;
		});

		.resp(767px,{
			padding: 15px 32px;
		});

		&:hover {
			background-color: transparent;
			color: @white;
		}
	}
}

.intro-conf-slide {
	height: auto !important;
	overflow: hidden;

	img {
		height: 1034px !important;

		.resp(1439px,{
			top: -60px !important;
		});

		.resp(1024px,{
			top: 0 !important;
			height: 100% !important;
		});
	}

	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.resp(767px,{
			align-items: flex-start;
		});
	}
}

.intro-homepage__control-prev,
.intro-homepage__control-next {
	width: 180px;
	height: 100%;
	display: inline-flex;
	align-items: center;
	cursor: pointer;

	.resp(1600px,{
		width: 100px;
	});

	.resp(1439px,{
		width: 80px;
	});

	.resp(767px,{
		width: 48px;
		height: 48px;
		top: 0;
		transform: translate(0)
	});
}

.intro-homepage__control-prev {
	justify-content: flex-end;
}

.intro-homepage__pagination {
	position: absolute;
	top: 140px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	column-gap: 10px;
	z-index: 2;

	.resp(1439px,{
		top: 110px;
	});

	.resp(1024px,{
		top: 85px;
	});

	.resp(999px,{
		top: 12px;
	});

	.resp(767px,{
		top: 19px;
	});
}

.swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: @white;
	opacity: 0.5;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
	opacity: 1;
}
