.conf {
	background-color: #151113;
	padding: 80px 0;
	background-image: url('./../img/conf-face.jpg');
	background-repeat: no-repeat;
	background-position: center right;
	background-size: contain;

	.resp(1439px,{
		padding: 60px 0;
		background-position: center right -180px;
	});

	.resp(767px,{
		background-image: url('./../img/conf-img-mobile.jpg');
		background-position: center;
		background-size: cover;
		min-height: 440px;
		display: flex;
		flex-direction: column;
	});

	.container {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__inner {
		width: 100%;
		background-image: url('./../img/ttl.svg'), url('./../img/tbl.svg');
		background-repeat: no-repeat;
		background-position: top left, bottom left;
		background-size: 58px, 58px;
		padding: 58px 58px 78px 58px;

		.resp(1439px,{
			padding: 32px 32px 52px 32px;
			background-size: 32px, 32px;
		});

		.resp(767px,{
			background: none;
			padding: 0;
		});
	}

	&__title {
		color: @white;
		font-size: 77px;
		font-weight: 800;
		line-height: 96%;
		letter-spacing: -3.08px;
		margin-bottom: 0;

		.resp(1439px,{
			font-size: 48px;
		});

		.resp(767px,{
			max-width: 80%;
		});
	}

	&__meta {
		color: @white;
		font-size: 18px;
		font-weight: 500;
		line-height: 130%;
		margin-top: 20px;

		.resp(1439px,{
			font-size: 16px;
			margin-top: 8px;
		});
	}

	&__content {
		max-width: 580px;
		margin-top: 40px;
		color: @white;
		font-size: 24px;
		font-weight: 500;
		line-height: 120%;

		.resp(1439px,{
			max-width: 398px;
			font-size: 18px;
			margin-top: 20px;
		});

		.resp(767px,{
			display: none;
		});
	}

	&__button {
		color: @white;
		font-size: 24px;
		font-weight: 800;
		line-height: 120%;
		margin-top: 80px;
		background-color: #ff0030;
		padding: 20px 40px;
		border-radius: 60px;
		display: inline-block;

		.resp(1439px,{
			margin-top: 40px;
			padding: 15px 44px;
			font-size: 18px;
		});

		.resp(767px,{
			padding: 15px 32px;
		});

		&:hover {
			color: @white;
		}
	}
}
