.intro {
	position: relative;
	overflow: hidden;
	z-index: 1;
	color: #fff;
	&.intro-homepage {
		.container {
			height: 934px;
			min-height: 0;
			.resp(1439px,{
				height: 660px;
				min-height: 0;
			});
			.resp(999px,{
				height: 500px;
				min-height: 0;
			});
			.resp(767px,{
				height: auto;
				min-height: 411px;
			});
		}
		.intro-box {
			//margin-top: 100px;
			padding-bottom: 100px;
			.resp(1439px,{
				padding-bottom: 35px;
				margin-top: 0;
			});
			.resp(999px,{
				padding-bottom: 15px;
			});
		}
	}
	.container {
		min-height: 840px;
		position: relative;
		z-index: 2;
		.resp(1439px,{
			min-height: 580px;
		});
		.resp(999px,{
			min-height: 430px;
		});
		.resp(767px,{
			min-height: 411px;
			display: flex;
			align-items: flex-end;
		});
	}
	.intro-bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		object-fit: cover;
	}
	.intro-box {
		padding-top: 257px;
		padding-bottom: 100px;
		.resp(1439px,{
			padding-top: 170px;
		});
		.resp(999px,{
			padding-top: 120px;
			padding-bottom: 48px;
		});
		.resp(767px,{
			flex: 1 1 100%;
			max-width: 100%;
			padding: 48px 0 48px 0;
		});
		> h1 {
			text-transform: uppercase;
		}
		> p {
			max-width: 560px;
			margin: 48px 0;
			.resp(1439px,{
				margin: 32px 0;
			});
			.resp(1279px,{
				max-width: 420px;
			});
			.resp(767px,{
				margin: 16px 0 24px 0;
				max-width: 340px;
			});
		}
		&--wide {
			.resp(767px,{
				padding-top: 65px;
			});

			> p {
				max-width: 665px;
				.resp(1279px,{
					max-width: 580px;
				});
			}
		}
	}
	.intro-info {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		.resp(767px,{
			display: none;
		});
		> li {
			flex: 0 0 auto;
			display: flex;
			flex-direction: column;
			& + li {
				margin-left: 105px;
				.resp(1279px,{
					margin-left: 72px;
				});
				.resp(999px,{
					margin-left: 40px;
				});
			}
			> p {
				margin: 6px 0 0 0;
				line-height: 1.4;
				.resp(1279px,{
					margin-top: 8px;
				});
			}
			> img {
				width: 60px;
				height: 60px;
			}
			> span {
				display: block;
				font-size: 48px;
				line-height: 60px;
				font-weight: bold;
				.resp(1279px,{
					font-size: 40px;
					line-height: 50px;
				});
				.resp(999px,{
					font-size: 36px;
					line-height: 42px;
				});
			}
		}
	}

	.breadcrumbs {
		position: absolute;
		top: 115px;
		.resp(1279px,{
			top: 93px;
		});
		.resp(999px,{
			top: 15px;
		});

		&__item {
			&:not(:last-child) {
				&::after {
					background-color: #f6f6f6;
				}
			}

			&:last-child {
				.breadcrumbs__link {
					color: #f6f6f6;
					font-weight: 700;
				}
			}
		}

		&__link {
			color: #f6f6f6;
		}
	}
}

.intro-soc {
	padding-bottom: 72px;

	.resp(767px,{
		padding-bottom: 40px;
	});

	.container {
		min-height: initial;

		.resp(767px,{
			display: block;
		});
	}

	.intro-box {
		padding-bottom: 24px;

		.resp(767px,{
			padding-bottom: 11px;
		});
	}
}

.intro-homepage__control-prev,
.intro-homepage__control-next {
	top: 50%;
	position: absolute;
	transform: translateY(-50%);
	z-index: 10;
}

.intro-homepage__control-prev {
	left: 0;
}

.intro-homepage__control-next {
	right: 0;
}
