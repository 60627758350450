.vacancy {
	padding: 32px;
	display: block;
	border-right: 4px solid @bg;
	&.is-active {
		background: @bg;
		border-color: @red;
	}
	> p {
		margin: 8px 0;
	}
	> span {
		font-weight: 500;
		font-size: 24px;
		line-height: 36px;
		display: block;
		margin-bottom: 16px;
	}
}

.vacancies {
	//max-height: 675px;
	overflow: auto;
}
