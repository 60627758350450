.reviews-special {
	background: @bg;
	overflow: hidden;
	.resp(767px,{
		background: none;
	});
	.container {
		padding-top: 140px;
		padding-bottom: 140px;
		position: relative;
		z-index: 1;
		.resp(1440px,{
			padding-top: 100px;
			padding-bottom: 100px;
		});
		.resp(1279px,{
			padding-top: 90px;
			padding-bottom: 90px;
		});
		.resp(999px,{
			padding-top: 80px;
			padding-bottom: 80px;
		});
		.resp(767px,{
			padding-top: 0;
			padding-bottom: 48px;
		});
	}
	&:before {
		position: absolute;
		right: 100%;
		top: 0;
		width: 9999px;
		height: 100%;
		content: '';
		background: #FFF;
		margin-right: 147px;
		.resp(999px,{
			display: none;
		});
	}
	.reviews-special-row {
		display: flex;
		align-items: center;
		.resp(767px,{
			display: block;
		});
		&:before {
			position: absolute;
			right: 100%;
			top: 0;
			width: 9999px;
			height: 100%;
			content: '';
			background: #FFF;
			margin-right: 147px;
			z-index: 5;
			.resp(767px,{
				display: none;
			});
		}
		.reviews-special-carousel {
			max-width: 734px;
			flex: 0 1 734px;
			position: relative;
			z-index: 1;
			.resp(1279px,{
				flex: 1 1 auto;
				min-width: 0;
				max-width: none;
			});
			&:before {
				position: absolute;
				right: 100%;
				top: -5%;
				width: 9999px;
				height: 110%;
				content: '';
				background: @bg;
				z-index: 2;
				.resp(767px,{
					display: none;
				});
			}
			.owl-stage-outer {
				overflow: visible;
				.owl-stage {
					display: flex;
					align-items: stretch;
					.owl-item {
						flex: 0 0 324px;
						float: none;
						height: auto;
						display: flex;
						align-items: stretch;
						width: 324px !important;
						.resp(1279px,{
							flex: 0 0 232px;
						});
						.resp(767px,{
							flex: 1 1 auto;
						});
					}
				}
			}
		}
		.reviews-special-text {
			max-width: 400px;
			flex: 0 0 400px;
			margin-right: 56px;
			position: relative;
			z-index: 2;
			.resp(1279px,{
				max-width: 256px;
				flex: 0 0 256px;
			});
			.resp(999px,{
				margin-right: 40px;
			});
			.resp(767px,{
				max-width: none;
			});
			> p {
				max-width: 320px;
				.resp(767px,{
					max-width: none;
				});
				> span {
					display: block;
					.resp(767px,{
						display: inline;
					});
				}
			}
			.block-header {
				margin-bottom: 40px;
				.resp(1279px,{
					margin-bottom: 24px;
				});
				.resp(767px,{
					margin-bottom: 16px;
				});
				> span {
					display: block;
					.resp(767px,{
						display: inline;
					});
				}
			}
			.carousel-nav {
				margin-top: 40px;
				.resp(767px,{
					display: none;
				});
			}
			.owl-dots {
				display: none;
				.resp(767px,{
					display: block;
				});
			}
		}
	}
}

.reviews-special-slide {
	width: 324px;
	position: relative;
	padding: 32px 20px 36px 24px;
	background: #FFF;
	box-sizing: border-box;
	.resp(1279px,{
		width: 232px;
	});
	.resp(999px,{
		padding: 30px 20px;
	});
	.resp(767px,{
		width: 100%;
		background: @bg;
	});
	.reviews-special-slide-info {
		overflow: hidden;
		display: flex;
		align-items: flex-start;
		min-height: 84px;
		.resp(999px,{
			min-height: 72px;
		});
		.reviews-special-slide-author {
			flex: 1 1 auto;
			min-width: 0;
			> p {
				margin: 0;
			}
			> span {
				display: block;
				font-weight: 500;
			}
		}
		.reviews-special-slide-avatar {
			width: 48px;
			height: 48px;
			border-radius: 50%;
			flex: 0 0 auto;
			display: block;
			margin: 5px 16px 0 0;
		}
	}
	.reviews-special-slide-text {
		margin-top: 24px;
	}
}
