.section-text-slider {
	&__inner {
		display: flex;
		column-gap: 32px;

		.resp(767px,{
			flex-direction: column;
		});
	}

	&__title {
		margin-bottom: 48px;

		.resp(1279px,{
			margin-bottom: 40px;
		});

		.resp(767px,{
			margin-bottom: 24px;
		});
	}

	&__content {
		width: 408px;
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		position: relative;
		z-index: 2;
		padding-bottom: 112px;

		.resp(1023px,{
			width: 300px;
		});

		.resp(767px,{
			width: 100%;
			padding-bottom: 0;
		});

		& > .button {
			.resp(767px,{
				display: none;
			});
		}

		&::before {
			content: '';
			position: absolute;
			width: 99999px;
			height: 100%;
			background-color: #fff;
			right: -32px;
			top: 0;
			z-index: -1;
		}
	}

	&__slider {
		flex: 1 1 auto;

		.owl-stage-outer {
			overflow: visible !important;
		}

		.owl-stage {
			display: flex;

			.owl-item {
				height: auto;
			}
		}
	}

	&__slide {
		width: 570px;
		height: 100%;
		background-color: #fff;
		border: 1px solid @line;
		display: flex;
		flex-direction: column;

		.resp(1279px,{
			width: 414px;
		});

		.resp(767px,{
			width: 100%;
		});

		img {
			width: 100%;
		}

		p {
			padding: 24px 32px;
			margin-top: 0;

			.resp(767px,{
				padding: 16px;
			});
		}
	}

	&__navigation {
		margin-left: 213px;
		margin-top: 48px;

		.resp(1279px,{
			margin-left: 143px;
			margin-top: 40px;
		});

		.resp(767px,{
			margin-left: auto;
			margin-right: auto;
		});
	}
}