.list {
	margin: 24px 0;
	padding: 0;
	list-style-type: none;
	.margin;
	> li {
		position: relative;
		padding-left: 36px;
		&:before {
			position: absolute;
			left: 0;
			top: 7px;
			width: 16px;
			height: 14px;
			content: '';
			.svg('check',@red);
			.resp(999px,{
				top: 3px;
			});
		}
		& + li {
			margin-top: 22px;
			.resp(999px,{
				margin-top: 18px;
			});
		}
	}
}

.list-line {
	margin: 32px 0;
	padding: 0;
	list-style-type: none;
	.margin;
	.resp(999px,{
		margin: 24px 0;
	});
	> li {
		line-height: 24px;
		position: relative;
		padding-left: 20px;
		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 2px;
			height: 24px;
			content: '';
			background: @red;
		}
		& + li {
			margin-top: 16px;
		}
	}
}