.photo-gallery {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 32px;

	.resp(1279px,{
		gap: 24px;
	});

	a {
		display: block;
		overflow: hidden;

		.resp(767px,{
			aspect-ratio: 1 / 1;
		});

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		&:nth-child(1) {
			grid-column: 2 span;
		}

		&:nth-child(5) {
			grid-column: 2 span;
			grid-row: 2 span;
		}

		&:nth-child(6) {
			grid-row: 2 span;
		}
	}
}

.carousel-nav-gallery {
	width: 100% !important;
	margin-top: 40px;
	display: flex;
	justify-content: center !important;
	column-gap: 16px;
}
