* {
	box-sizing: border-box;
}

.sorting-partners {
	margin-bottom: 50px;
	display: flex;
	row-gap: 8px;
	column-gap: 24px;

	@media screen and (max-width: 479px) {
		flex-direction: column;
	}

	&__item {
		width: 100%;
		max-width: 275px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		row-gap: 5px;

		@media screen and (max-width: 479px) {
			max-width: 100%;
		}
	}
}

.choices.select-partners {
	width: 100%;
	max-width: 275px;
	margin-bottom: 0;
	position: relative;

	@media screen and (max-width: 479px) {
		max-width: 100%;
	}
	
	.choices__inner {
		padding: 10px 16px 10px 24px;
		border: 1px solid #333;
		background-color: #fff;
		font-family: inherit;
		border-radius: 0;
	}

	.choices__list--single {
		padding: 0;
	}

	.choices__placeholder {
		.choices__button {
			display: none;
		}
	}

	&[data-type*=select-one] .choices__inner {
		padding-bottom: 10px;
	}

	&[data-type*=select-one]::after {
		width: 24px;
		height: 24px;
		background-image: url('./../img/arrow_down.svg');
		background-size: 100% 100%;
		border: none;
		margin: 0;
		transform: translateY(-50%);
		right: 16px;
	}

	&[data-type*=select-one].is-open {
		z-index: 99;
	}

	&[data-type*=select-one].is-open::after {
		margin: 0;
		transform: translateY(-50%) rotate(180deg);
	}

	.choices__list--dropdown, .choices__list[aria-expanded] {
		.choices__placeholder {
			display: none;
		}
	}

	.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
		padding: 10px 24px;
	}

	&[data-type*=select-one] .choices__button {
    background-size: 10px;
    top: 50%;
    right: 0;
		margin: 0;
    margin-right: 40px;
    height: 24px;
    width: 24px;
    border-radius: 0;
    opacity: 1;
		transform: translateY(-50%);

		&:hover {
			opacity: 0.5;
		}
	}
}