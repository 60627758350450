.section-accent {
	padding: 162px 0;
	background-image: url('./../pic/IndeedCM/section-accent.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left bottom;

	.resp(1279px,{
		padding: 75px 0;
	});

	.resp(767px,{
		padding: 35px 0;
		min-height: 400px;
		background-image: url('./..pic/IndeedCM/section-accent-mobile.jpg');
		background-position: right bottom;
	});

	&__title {
		max-width: 941px;
		margin-bottom: 0;

		.resp(1279px,{
			max-width: 470px;
		});
	}
}