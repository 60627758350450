.section-details {
	background-color: #f6f6f6;

	&__grid {
		display: grid;
		grid-template-columns: repeat(6, minmax(0, 1fr));
		gap: 32px;

		.resp(1279px,{
			gap: 24px;
		});
	}

	&__link {
		background-color: @red;
		grid-column: 3 span;
		color: #fff;
		padding: 32px;
		transition: background-color @hover;
		cursor: pointer;

		.resp(1279px,{
			padding: 32px 24px;
		});

		.resp(640px,{
			grid-column: 6 span;
			padding: 32px;
		});

		&:hover {
			color: #fff;
			background-color: @redhover;
		}
	}

	&__link-title {
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
		letter-spacing: 1.2px;
		text-transform: uppercase;

		.resp(767px,{
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 8px;
		});
	}

	&__link-text {
		.resp(1279px,{
			font-size: 14px;
		});
	}

	&__card {
		background-color: #fff;
		grid-column: 2 span;
		padding: 32px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.resp(991px,{
			grid-column: 3 span;
		});

		.resp(767px,{
			min-height: 233px;
		});

		.resp(640px,{
			grid-column: 6 span;
		});
	}

	&__card-title {
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
		letter-spacing: 1.2px;
		text-transform: uppercase;
		padding-top: 16px;
		position: relative;

		.resp(767px,{
			font-size: 16px;
			line-height: 24px;
			padding-top: 8px;
		});

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 48px;
			height: 2px;
			background-color: @red;

			.resp(767px,{
				width: 32px;
				height: 1px;
			});
		}
	}

	&__card-text {
		margin-top: 16px;
		margin-bottom: 32px;

		.resp(1279px,{
			font-size: 14px;
			line-height: 24px;
		});
	}

	&__card-link {
		line-height: 1;
		display: inline-block;
		margin-top: auto;
		position: relative;

		.resp(1279px,{
			font-size: 14px;
		});

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: @black;
			transition: opacity @hover;
		}

		&:hover {
			&::before {
				opacity: 0;
			}
		}
	}
}
