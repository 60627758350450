.section-requirements {
	padding: 140px 0;

	.resp(1279px,{
		padding: 88px 0;
	});

	.resp(767px,{
		padding: 48px 0;
	});

	&__title {
		max-width: 768px;

		.resp(1279px,{
			max-width: 100%;
		});
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		gap: 32px;

		.resp(1279px,{
			gap: 24px;
		});

		.resp(991px,{
			grid-template-columns: repeat(2, minmax(0, 1fr));
		});

		.resp(767px,{
			display: none;
		});
	}

	&__item {
		padding: 32px;
		border: 1px solid @line;
		position: relative;
		overflow: hidden;
		min-height: 324px;
		background-color: #fff;
		transition: background-color 0.5s ease-in-out, grid-template-rows 0.5s ease-in-out;
		display: grid;
		grid-template-rows: 0fr auto;

		.resp(1279px,{
			padding: 24px;
			min-height: 352px;
			background-color: #f6f6f6;
			grid-template-rows: 1fr auto;

			.section-requirements__item-title {
				&::before {
					opacity: 1;
					transform: translateY(0);
				}

				&::after {
					opacity: 0;
					transform: translateY(-16px);
				}
			}

			.section-requirements__item-text {
				p {
					opacity: 1;
				}
			}
		});

		.resp(991px,{
			min-height: 300px;
		});

		&:hover {
			background-color: #f6f6f6;
			grid-template-rows: 1fr auto;

			.section-requirements__item-title {
				&::before {
					opacity: 1;
					transform: translateY(0);
				}

				&::after {
					opacity: 0;
					transform: translateY(-16px);
				}
			}

			.section-requirements__item-text {
				p {
					opacity: 1;
				}
			}
		}
	}

	&__item-text {
		position: relative;
		z-index: 1;
		overflow: hidden;

		p {
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			line-clamp: 5;
			-webkit-box-orient: vertical;
			opacity: 0;
			transition: opacity 0.5s ease-in-out;

			.resp(1279px,{
				font-size: 14px;
				line-height: 24px;
				-webkit-line-clamp: 7;
				line-clamp: 7;
			});
		}
	}

	&__item-title {
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
		letter-spacing: 1.2px;
		text-transform: uppercase;
		position: relative;
		z-index: 1;
		align-self: start;

		.resp(767px,{
			font-size: 16px;
			line-height: 24px;
		});

		&::before {
			content: '';
			width: 80px;
			height: 2px;
			background-color: @red;
			position: absolute;
			top: -16px;
			left: 0;
			right: 0;
			transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
			transform: translateY(16px);
			opacity: 0;

			.resp(767px,{
				width: 32px;
			});
		}

		&::after {
			content: '';
			width: 100%;
			height: 2px;
			background-color: @red;
			position: absolute;
			bottom: -16px;
			left: 0;
			right: 0;
			transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
			transform: translateY(0);
		}

		span {
			color: @red;
		}
	}

	&__item-placeholder {
		color: #f6f6f6;
		font-size: 160px;
		line-height: 1;
		font-weight: 700;
		text-transform: uppercase;
		position: absolute;
		bottom: -24px;
		left: 50%;
		transform: translateX(-50%);
		pointer-events: none;
	}

	&__slider {
		display: none !important;

		.resp(767px,{
			display: block !important;
		});
	}
}
