.textfield {
    -webkit-border-radius: 0;
    border: 1px solid @line;
    width: 100%;
    box-sizing: border-box;
    display: block;
    margin: 0;
    outline: 0;
    height: 56px;
    padding: 15px 23px;
    font-size: 16px;
    line-height: 24px;
    background: #FFF;
    font-family: @font;
    color: @black;
    -webkit-appearance: none;
    .placeholder(@darkgray);
}