.section-savings {
	overflow: hidden;
	background-color: #f6f6f6;
	padding: 140px 0;

	.resp(1279px,{
		padding: 88px 0;
	});

	.resp(767px,{
		padding: 48px 0;
	});

	&__title {
		max-width: 776px;

		.resp(1279px,{
			max-width: 100%;
		});
	}

	&__slider {
		.owl-stage-outer {
			overflow: visible !important;
		}

		.owl-stage {
			display: flex;
		}

		.carousel-nav {
			margin-top: 48px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		row-gap: 8px;
		height: 100%;
	}

	&__item-header {
		background-color: #fff;
		padding: 40px 32px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		column-gap: 32px;
		row-gap: 8px;

		.resp(767px,{
			padding: 24px 16px;
		});

		.resp(479px,{
			flex-direction: column;
			align-items: flex-start;
		});
	}

	&__item-logo {
		display: inline-flex;
	}

	&__item-name {
		font-size: 24px;
		font-weight: 400;
		line-height: 36px;

		.resp(1279px,{
			font-size: 20px;
			line-height: 26px;
		});
	}

	&__item-label {
		font-size: 16px;
		line-height: 28px;
		padding: 10px 32px;
		border: 1px solid @black;

		.resp(767px,{
			display: none;
		});
	}

	&__item-body {
		flex: 1 1 auto;
		background-color: #fff;
		padding: 32px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.resp(767px,{
			padding: 16px 16px 32px 16px;
		});

		& > p {
			margin-bottom: 16px;
		}

		& > ul {
			list-style-type: none;
			margin: 0;
			padding-left: 27px;
			margin-bottom: 40px;

			& > li {
				position: relative;
				line-height: 28px;

				&::before {
					position: absolute;
					top: 6px;
					left: 0;
					content: '';
					width: 15px;
					height: 13px;
					background-image: url(../img/check-red.svg);
					background-size: 100% 100%;
					transform: translateX(-27px);
				}

				&:not(:last-child) {
					margin-bottom: 8px;
				}
			}
		}
	}

	&__item-bottom {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		justify-content: flex-end;
		column-gap: 32px;
		row-gap: 24px;
		margin-top: auto;

		.resp(767px,{
			flex-direction: column;
			align-items: flex-start;
		});

		& > .more-link {
			margin-right: auto;
		}
	}

	&--type2 {
		background-color: #fff;

		.section-savings__item-header,
		.section-savings__item-body {
			border: 1px solid #bdbdbd;
		}

		.review-info {
			margin-top: 16px;
			margin-bottom: 32px;
			display: flex;
			.resp(1279px,{
				margin-bottom: 24px;
			});
			.resp(767px,{
				margin-bottom: 16px;
			});
			.resp(479px,{
				flex-direction: column;
			});
			.review-author {
				flex: 1 1 auto;
				min-width: 0;
				> p {
					margin: 0;
					line-height: 24px;
				}
				> span {
					font-size: 20px;
					line-height: 26px;
					font-weight: 500;
					display: block;
					margin-bottom: 4px;
					.resp(1279px,{
						font-size: 20px;
						line-height: 30px;
					});
				}
			}
			.review-avatar {
				flex: 0 0 auto;
				width: 64px;
				height: 64px;
				border-radius: 50%;
				display: block;
				margin: 3px 24px 0 0;
				.resp(479px,{
					margin: 0 0 8px 0;
				});
			}
		}
	}
}
