.icon-list {
	margin: -36px -16px;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	.resp(999px,{
		margin: -24px -16px;
	});
	.resp(767px,{
		margin: 0;
		display: block;
	});
	> li {
		flex: 0 0 33.3333%;
		max-width: 33.3333%;
		padding: 36px 16px;
		box-sizing: border-box;
		.resp(999px,{
			padding: 24px 16px;
			flex: 1;
			min-width: 50%;
			max-width: 100%;
		});
		.resp(767px,{
			padding: 0;
			max-width: none;
			min-width: 0;
			& + li {
				margin-top: 32px;
			}
		});
		> h3 {
			margin-bottom: 16px;
			.resp(767px,{
				margin-bottom: 8px;
			});
		}
		> img {
			height: 72px;
			display: block;
			margin-bottom: 32px;
			.resp(767px,{
				height: 64px;
				margin-bottom: 16px;
			});
		}
		> p {
			margin: 0;
		}
	}
}

.icon-list-two {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	column-gap: 46px;
	margin: 0;
	padding: 0;
	.resp(1399px,{
		column-gap: 30px;
	});
	.resp(999px,{
		grid-template-columns: repeat(2, minmax(0, 1fr));
		row-gap: 54px;
	});
	.resp(540px,{
		grid-template-columns: repeat(1, minmax(0, 1fr));
		row-gap: 24px;
	});

	li {
		display: flex;
		flex-direction: column;

		&:nth-child(3) {
			h3 {
				max-width: 190px;
			}
		}

		img {
			margin-bottom: 16px;
			width: 72px;
			height: 72px;
			.resp(1399px,{
				width: 56px;
				height: 56px;
			});
			.resp(1023px,{
				width: 48px;
				height: 48px;
			});
		}

		h3 {
			margin-bottom: 8px;
			letter-spacing: 0.2px;
			.resp(767px,{
				font-size: 16px;
			});
		}

		p {
			margin: 0;
		}
	}
}

.icon-list-import {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 32px;
	.resp(1399px,{
		gap: 24px;
	});
	.resp(999px,{
		grid-template-columns: repeat(2, minmax(0, 1fr));
	});
	.resp(767px,{
		gap: 16px;
	});
	.resp(540px,{
		grid-template-columns: repeat(1, minmax(0, 1fr));
	});

	li {
		background-color: #fff;
		padding: 40px 32px;
		position: relative;
		display: flex;
		flex-direction: column;
		.resp(1399px,{
			padding: 16px;
			padding-bottom: 26px;
		});
		.resp(767px,{
			box-sizing: border-box;
			padding: 32px 24px;
			min-height: 218px;
		});

		&::after {
			position: absolute;
			content: "";
			bottom: 0;
			right: 0;
			width: 56px;
			height: 56px;
			background-color: #f0f0f0;
			.resp(1399px,{
				width: 40px;
				height: 40px;
			});
		}

		img {
			width: 72px;
			height: 72px;
			margin-bottom: 16px;
			.resp(1399px,{
				width: 56px;
				height: 56px;
				margin-bottom: 8px;
			});
			.resp(767px,{
				margin-bottom: 16px;
			});
		}

		h3 {
			margin-bottom: 8px;
			letter-spacing: 0.2px;
			.resp(767px,{
				font-size: 16px;
				line-height: 24px;
			});
		}

		p {
			margin: 0;
			.resp(1399px,{
				font-size: 14px;
				line-height: 24px;
			});
		}
	}
}
