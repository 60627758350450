.bottom-row {
	margin: -24px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-top: 48px;
	.bottom-row-box {
		padding: 24px;
		flex: 0 0 auto;
	}
}