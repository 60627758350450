.resp(@maxWidth; @rules) {
    @media only screen and (max-width: @maxWidth) {
        @rules();
    }
}

.respMin(@minWidth; @rules) {
    @media only screen and (min-width: @minWidth) {
        @rules();
    }
}

.respHeight(@maxHeight; @rules) {
    @media only screen and (max-height: @maxHeight) {
        @rules();
    }
}

.retina(@rules) {
    @media
		only screen and (min-width: 320px) and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (min-width: 320px) and (   min--moz-device-pixel-ratio: 1.3),
		only screen and (min-width: 320px) and (     -o-min-device-pixel-ratio: 4/3),
		only screen and (min-width: 320px) and (        min-device-pixel-ratio: 1.3),
		only screen and (min-width: 320px) and (                min-resolution: 124dpi),
		only screen and (min-width: 320px) and (                min-resolution: 1.3dppx) { 
		    @rules();
		}
}

.retinaWidth(@maxWidth;@rules) {
	@media
		only screen and (max-width: @maxWidth) and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (max-width: @maxWidth) and (   min--moz-device-pixel-ratio: 1.3),
		only screen and (max-width: @maxWidth) and (     -o-min-device-pixel-ratio: 4/3),
		only screen and (max-width: @maxWidth) and (        min-device-pixel-ratio: 1.3),
		only screen and (max-width: @maxWidth) and (                min-resolution: 124dpi),
		only screen and (max-width: @maxWidth) and (                min-resolution: 1.3dppx) { 
		    @rules();
		}
}

.orientation(@orientation; @rules) {
    @media(orientation:@orientation) {
		@rules();
    }
}

.placeholder(@color) {
	&::-webkit-input-placeholder {
	    opacity: 1;
	    color: @color;
	}
	&::-moz-placeholder {
	    opacity: 1;
	    color: @color;
	}
	&:-ms-input-placeholder {
		color: @color;
	}
}

.svg(@src, @fill-new) {
	@data-uri: data-uri('image/svg+xml;charset=UTF-8', '../img/@{src}.svg');
	@replace-default: escape('<path ');
	@replace-new: escape('<path fill="@{fill-new}" ');
	@replace-src: replace('@{data-uri}', @replace-default, @replace-new, 'g');
	background-image: e(@replace-src);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-color: transparent;
}

.svgcls(@src, @fill-new) {
	@data-uri: data-uri('image/svg+xml;charset=UTF-8', '../img/@{src}.svg');
	@replace-default: escape('.cls{}');
	@replace-new: escape('.cls{fill:@{fill-new}}');
	@replace-src: replace("@{data-uri}", @replace-default, @replace-new, "g");
	background-image: e(@replace-src);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-color: transparent;
}

.iefix(@rules){
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	    @rules();
	}
}