.logos {
	border-bottom: 1px solid @line;
	overflow: hidden;
	.industry + & {
		.resp(767px,{
			border-top: 1px solid @line;
		});
	}
	.container {
		height: 139px;
		display: flex;
		align-items: center;
		.resp(1279px,{
			height: 108px;
		});
		.resp(999px,{
			height: auto;
			display: block;
			padding-bottom: 16px;
		});
		> h3 {
			margin: 0 120px 0 0;
			flex: 0 0 auto;
			text-transform: initial;
			.resp(1279px,{
				margin-right: 40px;
			});
			.resp(999px,{
				font-size: 16px;
				line-height: 24px;
				padding: 24px 0 8px 0;
				margin: 0;
				background: #FFF;
				position: relative;
				z-index: 2;
			});
		}
	}
	.logos-carousel {
		flex: 1 1 auto;
		min-width: 0;
		position: relative;
		z-index: 1;
	}
	.logos-slide {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 1;
		transition: transform 1.5s ease, opacity 1.5s ease;
		transform: translateY(-100%);
		opacity: 0;
		&.is-active {
			z-index: 2;
			transform: translateY(0);
			opacity: 1;
		}
		&.is-hiding {
			transform: translateY(100%);
		}
		> ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
			align-items: center;
			display: flex;
			justify-content: space-between;
			.resp(767px,{
				margin: -5px;
			});
			> li {
				flex: 0 0 auto;
				.resp(767px,{
					flex: 0 0 20%;
					max-width: 20%;
					padding: 5px;
					box-sizing: border-box;
				});
				> img {
					display: block;
					.resp(767px,{
						margin: 0 auto;
					});
				}
			}
		}
	}
}
