.questionnaires {
	margin-top: 64px;

	.resp(1199px,{
		margin-top: 48px;
	});

	.resp(1023px,{
		margin-top: 40px;
	});

	&__title {
		font-weight: 400;
		margin-bottom: 40px;

		.resp(1199px,{
			margin-bottom: 32px;
			font-size: 20px;
		});

		.resp(767px,{
			margin-bottom: 24px;
		});
	}
}
