.video {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	> iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.video-play {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		overflow: hidden;
		cursor: pointer;
		&:hover {
			&:after {
				.svg('play',@red);
			}
			&:before {
				background: #FFF;
			}
		}
		&:after,
		&:before {
			position: absolute;
			left: 50%;
			top: 50%;
			content: '';
			transform: translate(-50%,-50%);
		}
		&:after {
			width: 18px;
			height: 18px;
			margin-left: 3px;
			transition: background @hover;
			.svg('play',#FFF);
			z-index: 3;
			.resp(999px,{
				width: 12px;
				height: 12px;
				margin-left: 2px;
			});
			.resp(767px,{
				width: 9px;
				height: 9px;
				margin-left: 1px;
			});
		}
		&:before {
			width: 72px;
			height: 72px;
			border-radius: 50%;
			z-index: 2;
			background: @red;
			transition: background @hover;
			.resp(999px,{
				width: 54px;
				height: 54px;
			});
			.resp(767px,{
				width: 40px;
				height: 40px;
			});
		}
		> img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			min-width: 100%;
			min-height: 100%;
		}
	}
}