.consult {
	.resp(767px,{
		background: @bg;
		overflow: hidden;
		padding: 48px 0;
	});
	&.consult-bg {
		background: @bg;
		overflow: hidden;
		.resp(999px,{
			background: none;
		});
		.col-right {
			&:after {
				display: none;
			}
		}
		.consult-team {
			background: #FFF;
			position: relative;
			&:after {
				width: 147px;
				height: 100%;
				top: 0;
				position: absolute;
				content: '';
				left: 100%;
				background: #FFF;
				.resp(999px,{
					display: none;
				});
			}
			&:before {
				width: 16px;
				height: 100%;
				top: 0;
				position: absolute;
				content: '';
				right: 100%;
				background: #FFF;
				.resp(999px,{
					display: none;
				});
			}
			.consult-team-box {
				&:first-child {
					.consult-team-image {
						&:before {
							display: none;
						}
					}
				}
			}
		}
		.container {
			&:after {
				background: #FFF;
			}
		}
	}
	&.consult-reverse {
		.col-left {
			order: 2;
			margin-right: 0;
			margin-left: 60px;
			.resp(999px,{
				margin-left: 0;
			});
		}
		.col-right {
			order: 1;
			&:before {
				margin: 0 -1px 0 0;
				left: auto;
				right: 100%;
			}
			&:after {
				left: auto;
				right: 100%;
				margin: 0 180px 0 0;
			}
		}
		.consult-team {
			.consult-team-box {
				&:first-child {
					.consult-team-image {
						&:before {
							left: auto;
							right: 0;
							margin-right: -40px;
						}
					}
				}
			}
		}
		.container {
			&:after {
				right: auto;
				left: 100%;
				margin: 0 0 0 147px;
			}
		}
	}
	.col-left {
		.resp(767px,{
			margin: 0;
		});
		> p {
			.resp(767px,{
				margin-bottom: 0;
			});
		}
		.block-header {
			margin-bottom: 40px;
			.resp(1279px,{
				margin-bottom: 24px;
			});
		}
		.button {
			margin-top: 20px;
			.resp(999px,{
				margin-top: 0;
			});
		}
	}
	.col-right {
		position: relative;
		.resp(999px,{
			padding-top: 20px;
		});
		.resp(767px,{
			padding-top: 24px;
		});
		&:after {
			position: absolute;
			left: 100%;
			margin-left: 180px;
			top: 232px;
			width: 72px;
			height: 140px;
			background: url('../img/lines-vertical.svg') no-repeat;
			background-size: 100% 100%;
			content: '';
			z-index: 2;
			.resp(1279px,{
				display: none;
			});
			.resp(767px,{
				background-image: url('../img/lines.svg');
				width: 72px;
				height: 40px;
				top: 24px;
				right: -16px;
				margin: 0;
				left: auto;
				display: block;
			});
		}
	}
	.container {
		position: relative;
		&:after {
			position: absolute;
			right: 100%;
			margin-right: 147px;
			top: 0;
			width: 9999px;
			height: 100%;
			background: @bg;
			content: '';
		}
	}
	.row {
		align-items: center;
	}
}

.consult-carousel-box {
	.resp(767px,{
		display: flex;
		align-items: center;
		& + .consult-carousel-box {
			margin-top: 16px;
		}
	});
	.consult-carousel-image {
		margin-bottom: 40px;
		.resp(767px,{
			flex: 0 0 80px;
			max-width: 80px;
			margin: 0 16px 0 0;
		});
		> img {
			display: block;
			width: 100%;
		}
	}
	.consult-carousel-info {
		> h4 {
			margin-bottom: 8px;
		}
		> span {
			display: block;
		}
	}
}

.consult-team {
	margin: -20px -15px;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 2;
	.resp(1279px,{
		margin: -16px;
	});
	.resp(999px,{
		text-align: center;
	});
	.resp(767px,{
		display: none;
	});
	.consult-team-box {
		flex: 0 0 50%;
		max-width: 50%;
		box-sizing: border-box;
		padding: 20px 16px;
		position: relative;
		z-index: 2;
		.resp(1279px,{
			padding: 16px;
		});
		&:first-child {
			position: relative;
			z-index: 1;
			.consult-team-image {
				position: relative;
				&:before {
					position: absolute;
					left: 0;
					top: 0;
					width: 9999px;
					height: 100%;
					background: @bg;
					content: '';
					z-index: 1;
				}
				> img {
					position: relative;
					z-index: 2;
				}
			}
		}
		.consult-team-image {
			> img {
				display: block;
				width: 100%;
				margin-bottom: 24px;
			}
		}
		.consult-team-info {
			> h4 {
				margin-bottom: 8px;
			}
			> span {
				display: block;
			}
		}
	}
}

