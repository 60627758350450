.tabs-nav {
	margin: -12px;
	padding: 0 0 72px 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	.resp(1279px,{
		padding-bottom: 40px;
	});
	.resp(767px,{
		padding-bottom: 24px;
		margin: -6px;
	});
	&:last-child {
		padding-bottom: 0;
	}
	> li {
		flex: 0 0 auto;
		margin: 12px;
		.resp(767px,{
			margin: 6px;
		});
		> a {
			border: 1px solid @black;
			display: block;
			padding: 5px 24px;
			transition: border @hover, color @hover;
			.resp(1279px,{
				padding: 8px 24px;
			});
			.resp(767px,{
				padding: 4px 16px;
			});
			&:hover {
				border-color: @blackhover;
				color: @blackhover;
			}
		}
	}
	.is-active {
		cursor: default;
		background: @black;
		&,
		&:hover {
			color: #FFF;
			border-color: @black;
		}
	}
}