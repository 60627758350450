@max-width: 1920px;
@breakpoint-1440: 1440px;
@breakpoint-1024: 1024px;
@breakpoint-320: 320px;

.startup-container {
    max-width: @max-width;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .content {
        width: 900px;
        h1 {
            font-size: 64px;
            @media (max-width: @breakpoint-1024) {
                font-size: 30px;
            }
        }
        p {
            font-size: 24px;
            @media (max-width: @breakpoint-1024) {
                font-size: 20px;
            }
        }
        button {
            font-size: 24px;
            background-color: red;
            color: white;
            border: none;
            padding: 10px 20px;
            cursor: pointer;
            @media (max-width: @breakpoint-1024) {
                font-size: 20px;
            }
        }
    }

    .image {
        .large-image {
            width: auto;
            height: 500px;
            @media (max-width: 1000px) {
                display: none;
            }
        }
        .small-image {
            display: none;
            @media (max-width: 1000px) {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }

    @media (max-width: @breakpoint-1440) {
        flex-direction: column;
        .content, .image {
            width: 100%;
        }
    }
}