button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.button,
.button-red {
	display: inline-block;
	vertical-align: top;
	max-width: 100%;
	color: #FFF;
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.05em;
	text-align: center;
	font-family: @font;
	padding: 18px 48px;
	font-weight: bold;
    text-transform: uppercase;
   	text-decoration: none;
	background: @black;
	border: 0;
	-webkit-border-radius: 0;
	-webkit-appearance: none;
	overflow: visible;
	box-sizing: border-box;
	outline: 0;
	margin: 0;
    cursor: pointer;
	transition: background @hover;
	.resp(1279px,{
		padding: 16px 32px;
	});
	.resp(767px,{
		font-size: 12px;
		line-height: 16px;
		padding: 12px 24px;
	});
	&:hover {
		background: @blackhover;
		color: #FFF;
	}
	&.button-red {
		background: @red;
		&:hover {
			background: @redhover;
		}
	}
	&.button-small {
		padding-top: 14px;
		padding-bottom: 14px;
	}
	&:disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

.button-box {
	text-align: center;
	margin-top: 77px;
	.resp(1279px,{
		margin-top: 40px;
	});
}

.button-content{
	margin-top: 48px;
	.resp(1279px,{
		margin-top: 40px;
	});
}