.info-block {
	position: relative;
	z-index: 1;
	.section;
	margin-bottom: 140px;
	.resp(1440px,{
		margin-bottom: 100px;
	});
	.resp(1279px,{
		margin-bottom: 0px;
	});
	.resp(999px,{
		margin-bottom: 0px;
	});
	.resp(767px,{
		margin-bottom: 0px;
	});
	&:after {
		width: 1662px;
		position: absolute;
		left: 50%;
		top: 0;
		height: 100%;
		content: '';
		z-index: 2;
		background: @bg;
		transform: translateX(-50%);
	}
	&:before {
		width: 9999px;
		position: absolute;
		left: 50%;
		top: 0;
		height: 100%;
		content: '';
		z-index: 1;
		background: @bg;
	}
	.container {
		position: relative;
		z-index: 3;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.resp(1200px,{
			padding: 0;
		});
		.resp(999px,{
			display: block;
			padding: 0 16px;
		});
	}
	.info-block-text {
		flex: 0 1 498px;
		max-width: 498px;
		margin-right: 40px;
		.resp(1200px,{
			flex: 0 0 50%;
			max-width: 50%;
			padding: 0 32px;
			box-sizing: border-box;
			margin: 0;
		});
		.resp(999px,{
			max-width: none;
			padding: 0;
			margin-bottom: 40px;
		});
		.resp(767px,{
			margin: 0;
		});
		> p {
			& + .button {
				margin-top: 20px;
				.resp(999px,{
					margin-top: 8px;
				});
				.resp(767px,{
					margin: 0;
				});
			}
		}
		.block-header {
			margin-bottom: 40px;
			.resp(767px,{
				margin-bottom: 24px;
			});
		}
	}
	.info-block-media {
		flex: 0 0 665px;
		max-width: 665px;
		.resp(1200px,{
			flex: 0 0 50%;
			max-width: 50%;
			padding: 0 32px;
			box-sizing: border-box;
		});
		.resp(999px,{
			max-width: none;
			padding: 0;
		});
		.info-block-lines {
			position: relative;
			&:after {
				position: absolute;
				right: -70px;
				bottom: 72px;
				width: 140px;
				height: 70px;
				background: url('../img/lines.svg') no-repeat;
				background-size: 100% 100%;
				content: '';
				z-index: 10;
				.resp(999px,{
					width: 35px;
					height: 70px;
					right: 36px;
					bottom: auto;
					top: -35px;
					background-image: url('../img/lines-vertical.svg');
				});
				.resp(767px,{
					display: none;
				});
			}
			> img {
				display: block;
				width: 100%;
			}
		}
		.video {
			&.is-loaded {
				&:after {
					display: none;
				}
			}
			.resp(767px,{
				margin-top: 40px;
			});
		}
	}
	&--clean {
		margin-bottom: 0;
		&:after,
		&:before {
		  display: none;
		}
		.info-block-lines {
		  &:after {
			display: none;
		  }
		}
	  }
}

.info-block.background{
  .container{
	.resp(999px,{
		display: flex;
	});

	.resp(540px,{
		display: block;
		
		.info-block-media{
			display: none;
		}
	});
  }
  .info-block-text{
	  .info-block-lines{
		  display: none;
		  .resp(540px,{
			display: block;
		});
	  }
  }
}
