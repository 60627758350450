html {
    margin: 0;
    padding: 0;
    height: 100%;
	.iefix({
    	min-height: 100%;
    	height: auto;
	});
}

body {
	margin: 0;
    position: relative;
    font-size: 16px;
	line-height: 28px;
	font-family: @font;
	color: @black;
    background: #FFF;
	-webkit-text-size-adjust: 100%;
	min-width: 320px;
	min-height: 100%;
	.resp(999px,{
		font-size: 14px;
		line-height: 24px;
		padding-top: 56px;
		box-sizing: border-box;
	});
	&.is-locked {
		width: 100%;
		padding-right: 0;
		overflow-y: scroll;
		position: fixed;
	}
	&.header-white {
		header {
			background: #fff;
			border-bottom: 1px solid #e0e0e0;

			.nav > ul > li > a {
				color: #333;
				&:after{
					color: #333;
				}
			}

			.logo {
				background-image: url('../img/logo-black.svg');
			}
		}
	}

}

img {
	border: 0;
	max-width: 100%;
}

form,
fieldset {
	margin: 0;
	padding: 0;
	border: 0 none;
}

a {
	outline: 0;
	color: @black;
	text-decoration: none;
	transition: color @hover;
	&:hover {
		color: @blackhover;
	}
}

blockquote {
	margin: 40px 0;
	.margin;
	padding: 0 0 0 40px;
	border-left: 28px solid @red;
	> p {
		margin: 24px 0;
		font-weight: 300;
	}
	> span {
		margin: 24px 0;
		display: block;
		.margin;
	}
}

h1 {
	font-size: 56px;
	line-height: 78px;
	font-weight: bold;
	margin: 0 0 48px 0;
	text-transform: uppercase;
	.resp(1440px,{
		font-size: 48px;
		line-height: 60px;
		margin-bottom: 40px;
	});
	.resp(1279px,{
		font-size: 40px;
		line-height: 50px;
		margin-bottom: 32px;
	});
	.resp(999px,{
		font-size: 32px;
		line-height: 42px;
	});
	.resp(767px,{
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 16px;
	});
}

h2 {
	font-size: 48px;
	line-height: 60px;
	font-weight: bold;
	margin: 0 0 40px 0;
	.resp(1279px,{
		font-size: 32px;
		line-height: 42px;
	});
	.resp(767px,{
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 24px;
	});
	> span {
		color: @red;
	}
}

h3 {
	font-size: 24px;
	line-height: 36px;
	font-weight: bold;
	margin: 0 0 24px 0;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	.resp(1279px,{
		font-size: 20px;
		line-height: 32px;
	});
	> span {
		color: @red;
	}
	
	
}

h4 {
	font-size: 24px;
	line-height: 36px;
	font-weight: 500;
	margin: 0 0 24px 0;
	.resp(1279px,{
		font-size: 20px;
		line-height: 32px;
		margin-bottom: 16px;
	});
	.resp(999px,{
		font-size: 18px;
		line-height: 30px;
	});
	.resp(767px,{
		font-size: 16px;
		line-height: 28px;
	});
}

h5 {
	font-size: 24px;
	line-height: 36px;
	font-weight: normal;
	margin: 0 0 30px 0;
	.resp(1279px,{
		font-size: 18px;
		line-height: 26px;
		margin-bottom: 16px;
	});
	.resp(999px,{
		font-size: 16px;
		line-height: 28px;
	});
	.resp(767px,{
		font-size: 14px;
		line-height: 24px;
	});
}

p {
	margin: 28px 0;
	.margin;
	.resp(999px,{
		margin: 24px 0;
	});
}

.noupper{
	text-transform: none;
}

.table-hide{
	.resp(767px,{
		display: none;
	});
}

.block-header {
	margin-bottom: 74px;
	text-transform: uppercase;
	padding-top: 26px;
	position: relative;
	.resp(1279px,{
		margin-bottom: 40px;
		padding-top: 18px;
	});
	.resp(767px,{
		margin-bottom: 24px;
		padding-top: 17px;
	});
	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 64px;
		height: 2px;
		content: '';
		background: @red;
		.resp(1279px,{
			width: 56px;
		});
		.resp(767px,{
			width: 32px;
			height: 1px;
		});
	}
}

.container {
	max-width: 1370px;
	padding: 0 32px;
	box-sizing: border-box;
	margin: 0 auto;
	width: 100%;
	.resp(999px,{
		padding: 0 16px;
	});
}

.cover {
	//background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
}

.margin {
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.mobile-hidden {
	.resp(767px,{
		display: none;
	});
}

.mobile-only {
	display: none;
	.resp(767px,{
		display: block;
	});
}

.overflow {
	overflow: hidden;
}

.tablet-only {
	display: none;
	.resp(999px,{
		display: block;
	});
}

.section {
	overflow: hidden;
	padding: 140px 0;
	.resp(1440px,{
		padding: 100px 0;
	});
	.resp(1279px,{
		padding: 90px 0;
	});
	.resp(999px,{
		padding: 80px 0;
	});
	.resp(767px,{
		padding: 48px 0;
	});
	& + .section {
		padding-top: 0;
	}
	&--breadcrumbs-padding {
		padding: 115px 0 !important;
		.resp(1440px,{
			padding: 115px 0 !important;
		});
		.resp(1279px,{
			padding: 93px 0 !important;
		});
		.resp(999px,{
			padding: 25px 0 !important;
		});
		& + .section {
			padding-top: 0;
		}
	}
}

.section-gray {
	background-color: #F6F6F6;
	overflow: hidden;
	padding: 140px 0;
	.resp(1440px,{
		padding: 100px 0;
	});
	.resp(1279px,{
		padding: 90px 0;
	});
	.resp(999px,{
		padding: 80px 0;
	});
	.resp(767px,{
		padding: 48px 0;
	});
	.i-box .i-box-image > img {
		.resp(479px,{
				height: 218px;
				object-fit: cover;
			});
	  }
}


.set-mode {
	z-index: 1920;
	position: relative;
	display: none;
	.resp(767px,{
		z-index: 767;
	});
}

.section-internship {
	.block-header {
		margin-bottom: 48px;

		.resp(1279px,{
      margin-bottom: 24px;
    });
	}

	&__text {
		max-width: 500px;
		margin-bottom: 32px;
	}

	&__btn-wrap {
		display: flex;
		justify-content: center;
		margin-top: 72px;

		.resp(1279px,{
      margin-top: 56px;
    });
	}
}

.section-event {
	.container {
		display: flex;
		justify-content: space-between;
		column-gap: 32px;

		.resp(767px,{
      flex-direction: column;
      row-gap: 24px;
    });
	}

	.info-section-event {
		margin-left: 0;
		max-width: 664px;

		.resp(1279px,{
      max-width: 464px;
    });

		.resp(767px,{
      min-width: initial;
    });
	}

	&__img-wrap {
		overflow: hidden;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
}

.section-achievements {
	.about {
		.resp(767px,{
      margin: 0;
    });
	}

	.about .about-number {
		.resp(1279px,{
      min-height: 124px;
      line-height: 124px;
      font-size: 80px;
    });

		.resp(767px,{
      flex: 0 0 120px;
      max-width: initial;
    });
	}
}

.sect-conf {
	.info-section-content {
		flex: initial !important;
		padding-left: 0 !important;
	}
	.info-section-event {
		.resp(1279px,{
      min-width: initial;
    });
	}
}

