.partner {
	flex: 1 1 100%;
	max-width: 100%;
	box-sizing: border-box;
	background: @bg;
	padding: 40px;
	display: flex;
	flex-direction: column;
	min-height: 547px;
	.resp(1279px,{
		min-height: 440px;
	});
	.resp(767px,{
		padding: 30px;
		min-height: 380px;
	});
	.resp(419px,{
		padding: 30px 24px 24px 24px;
	});
	.partner-about {
		flex: 1 1 auto;
		width: 100%;
		margin-bottom: 32px;
		p {
			margin: 28px 0;
			.margin;
			.resp(999px,{
				margin: 24px 0;
			});
		}
	}
	.partner-logo {
		margin-bottom: 32px;
		flex: 0 0 auto;
		.resp(767px,{
			margin-bottom: 16px;
		});
		> img {
			display: block;
			height: 48px;
			.resp(767px,{
				height: 32px;
			});
		}
	}
	.partner-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		flex: 0 0 auto;
		.more-link {
			flex: 0 0 auto;
		}
		.partner-link {
			flex: 0 0 auto;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.partners {
	display: flex;
	align-items: stretch;
	margin: -16px;
	flex-wrap: wrap;
	.resp(767px,{
		margin: 0;
		display: block;
	});
	.partners-box {
		flex: 0 0 33.3333%;
		max-width: 33.3333%;
		box-sizing: border-box;
		padding: 16px;
		display: flex;
		align-items: stretch;
		.resp(999px,{
			flex: 0 0 50%;
			max-width: 50%;
		});
		.resp(767px,{
			max-width: none;
			padding: 0;
			& + .partners-box {
				margin-top: 30px;
			}
		});
		&.is-hidden {
			display: none;
		}
	}
}