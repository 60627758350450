.products {
	.block-header {
		margin-bottom: 48px;
		.resp(1279px,{
			margin-bottom: 40px;
		});
		.resp(767px,{
			margin-bottom: 0;
		});

	}
	/*
	.tablet-only {
		display: none;
		.resp(999px,{
			display: block;
			margin-bottom: 0px;
		});
	}*/
}

.products-carousel {
	margin: 0 -48px;
	padding: 42px 48px;
	background: @bg;
	flex: 1 1 auto;
	min-width: 0;
	display: flex;
	align-items: center;
	.resp(1279px,{
		margin: 0 -32px;
		padding: 32px;
	});
	.resp(999px,{
		margin: 0 -16px;
	});
	.resp(767px,{
		margin: 0;
		padding: 0;
		background: none;
		.owl-carousel {
			&.owl-loaded {
				.owl-stage {
					display: flex;
					align-items: stretch;
					.owl-item {
						flex: 1 1 auto;
						min-width: 0;
						float: none;
						height: auto;
						display: flex;
						align-items: stretch;
						padding: 0 1px;
					}
				}
			}
		}
	});
	.products-carousel-box {
		flex: 1 1 100%;
		max-width: 100%;
	}
}

.products-list {
	margin: -16px;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	.resp(767px,{
		margin: 0;
		display: block;
		order: 2;
		flex: 0 0 auto;
	});
	&.owl-carousel {
		width: auto;
		.owl-item {
			box-sizing: border-box;
		}
	}
	.products-list-box {
		flex: 0 0 50%;
		max-width: 50%;
		box-sizing: border-box;
		padding: 16px;
		display: flex;
		align-items: stretch;
		.resp(767px,{
			padding: 0 1px;
			max-width: none;
			box-sizing: border-box;
			flex: 1 1 100%;
			max-width: 100%;
		});
		&:nth-child(even) {
			transform: translateY(72px);
			.resp(1279px,{
				transform: translateY(32px);
			});
			.resp(999px,{
				transform: none;
			});
		}
		.card {
			.respMin(768px,{
				border: 0;
			});
		}
	}
}

.products-list-info {
	display: none;
	margin-top: 32px;
	.resp(767px,{
		display: block;
		width: 100%;
		flex: 0 0 auto;
		order: 1;
		margin: 0 0 24px 0;
	});
	> h4 {
		margin-bottom: 8px;
	}
	> p {
		margin: 0 0 8px 0;
		.resp(767px,{
			margin: 0;
		});
	}
	.more-link {
		.resp(767px,{
			display: none;
		});
	}
}

.products-mobile-links {
	display: none;
	align-items: center;
	margin-top: 32px;
	order: 3;
	flex: 0 0 auto;
	width: 100%;
	.resp(767px,{
		display: flex;
	});
	.button {
		flex: 0 0 auto;
		margin-right: 16px;
	}
	.video-link {
		flex: 0 0 auto;
	}
}

.products-slide {
	padding-bottom: 72px;
	.resp(1279px,{
		padding-bottom: 32px;
	});
	.resp(767px,{
		padding: 0;
		display: flex;
		flex-direction: column;
	});
	& + .products-slide {
		.resp(767px,{
			margin-top: 48px;
		});
	}
}

.products-tab {
	padding: 32px 40px;
	display: block;
	transition: background @hover;
	position: relative;
	.resp(1279px,{
		padding: 24px 34px;
	});
	.resp(999px,{
		padding: 24px;
		flex: 0 0 33.3333%;
		max-width: 33.3333%;
		box-sizing: border-box;
	});
	&.is-active {
		background: @bg;
		&:after {
			opacity: 1;
		}
	}
	&:hover {
		background: @bg;
		color: @black;
	}
	&:after {
		left: 100%;
		top: 50%;
		border: solid transparent;
		content: '';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: fade(@bg,0);
		border-left-color: @bg;
		border-width: 10px;
		margin-top: -10px;
		opacity: 0;
		transition: opacity @hover;
	}
	> h4 {
		margin-bottom: 16px;
	}
	> p {
		margin: 0 0 24px 0;
		.resp(1279px,{
			margin-bottom: 16px;
		});
	}
}

.products-tabs {
	.resp(999px,{
		display: flex;
		align-items: stretch;
	});
	.resp(767px,{
		display: none;
	});
}

.prod-import {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 32px;
	.resp(1399px,{
		gap: 24px;
	});
	.resp(767px,{
		grid-template-columns: repeat(1, minmax(0, 1fr));
		gap: 16px
	});

	&__item {
		display: flex;
		flex-direction: column;
		background-color: #f6f6f6;

		.more-link {
			margin-top: auto;
		}
	}

	&__header {
		padding: 40px;
		border-bottom: 2px solid #fff;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.resp(1399px,{
			padding: 24px 16px;
		});

		h3 {
			margin-bottom: 8px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			height: 72px;
			.resp(1279px,{
				height: 64px;
			});
			.resp(767px,{
				height: auto;
				font-size: 16px;
				line-height: 24px;
			});
		}

		p {
			margin: 0;
			margin-bottom: 16px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			height: 56px;
			.resp(999px,{
				height: 48px;
			});
			.resp(767px,{
				height: auto;
				-webkit-line-clamp: initial;
			});
		}

		.more-link {
			margin-top: auto;
		}
	}


	&__body {
		padding: 40px;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.resp(1399px,{
			padding: 24px 16px;
		});

		p {
			margin: 0;
			margin-bottom: 8px;
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding-left: 27px;

			li {
				position: relative;
				line-height: 28px;
				&:not(:last-child) {
					margin-bottom: 8px;
				}

				&::before {
					position: absolute;
					top: 6px;
					left: 0;
					content: "";
					width: 15px;
					height: 13px;
					background-image: url('../img/check-red.svg');
					background-size: 100% 100%;
					transform: translateX(-27px);
				}
			}
		}
	}
}
