.section-guard {
	background-color: #f6f6f6;
	padding: 80px 0 !important;
	.resp(1440px,{
		padding: 100px 0 !important;
	});
	.resp(1279px,{
		padding: 90px 0 !important;
	});
	.resp(999px,{
		padding: 56px 0 !important;
	});
	.resp(767px,{
		padding: 48px 0;
	});

	&__slider {
		.owl-stage {
			display: flex;
		}
	}

	&__title {
		max-width: 790px;
		margin-bottom: 0;
	}

	&__subtitle {
		font-size: 32px;
		font-weight: 700;
		line-height: 42px;
		text-transform: uppercase;
		margin-bottom: 48px;

		.resp(1279px,{
			font-size: 24px;
			line-height: 36px;
			margin-bottom: 32px;
		});

		.resp(767px,{
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 16px;
		});
	}

	&__white {
		background-color: #fff;

		.section-guard__card {
			background-color: #f6f6f6;
		}
	}

	&__block {
		padding: 88px 0;

		.resp(1279px,{
			padding: 56px 0;
		});

		.resp(767px,{
			padding: 32px 0;
		});

		&--pb-none {
			padding-bottom: 0;
		}
	}

	&__card {
		height: 100%;
		min-height: 428px;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		row-gap: 44px;
		padding: 40px 0;

		.resp(1279px,{
			padding: 24px 0;
		});

		.resp(767px,{
			min-height: initial;
			row-gap: 24px;
		});
	}

	&__card-text {
		padding: 0 40px;

		.resp(1279px,{
			padding: 0 24px;
		});

		.resp(767px,{
			padding-right: 14px;
		});
	}

	&__card-label {
		display: inline-flex;
		align-items: flex-end;
		justify-content: flex-end;
		margin-top: auto;
		padding: 0 40px;
		text-align: right;
		color: #ff3f23;
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
		letter-spacing: 1.2px;
		text-transform: uppercase;
		border-right: 8px solid #ff3f23;
		min-height: 108px;

		.resp(1279px,{
			padding: 0 24px;
			font-size: 20px;
			line-height: 26px;
			border-width: 4px;
			min-height: 78px;
		});

		.resp(767px,{
			padding-right: 14px;
			font-size: 16px;
			line-height: 24px;
			min-height: 72px;
		});
	}
}
