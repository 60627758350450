.review {
	.review-info {
		margin-bottom: 40px;
		display: flex;
		.resp(1279px,{
			margin-bottom: 24px;
		});
		.resp(767px,{
			margin-bottom: 16px;
		});
		.review-author {
			flex: 1 1 auto;
			min-width: 0;
			> p {
				margin: 0;
				line-height: 24px;
			}
			> span {
				font-size: 24px;
				line-height: 36px;
				font-weight: 500;
				display: block;
				margin-bottom: 8px;
				.resp(1279px,{
					font-size: 20px;
					line-height: 30px;
				});
				.resp(767px,{
					font-size: 16px;
					line-height: 28px;
					margin-bottom: 6px;
				});
			}
		}
		.review-avatar {
			flex: 0 0 auto;
			width: 64px;
			height: 64px;
			border-radius: 50%;
			display: block;
			margin: 3px 24px 0 0;
			.resp(1279px,{
				width: 56px;
				height: 56px;
				margin-top: 0;
			});
			.resp(767px,{
				width: 48px;
				height: 48px;
				margin: 3px 16px 0 0;
			});
		}
	}
	.review-text {
		font-weight: 300;
		margin-bottom: 40px;
		.resp(1279px,{
			margin-bottom: 24px;
		});
		.resp(767px,{
			margin-bottom: 16px;
			font-weight: 300;
		});
	}
}

.reviews {
	.block-header {
		& + img {
			display: none;
			width: 100%;
			margin-bottom: 36px;
			.resp(767px,{
				display: block;
			});
		}
	}
	.col-left {
		flex: 1 1 auto;
		min-width: 0;
		max-width: none;
		margin-right: 60px;
		.resp(1440px,{
			flex: 0 1 538px;
			max-width: 538px;
			margin-left: -32px;
		});
		.resp(1200px,{
			flex: 0 0 396px;
			max-width: 396px;
		});
		.resp(999px,{
			flex: 0 0 320px;
			max-width: 320px;
			margin-right: 30px;
		});
		.resp(767px,{
			display: none;
		});
		> img {
			display: block;
			width: 100%;
			.respMin(1441px,{
				max-width: 688px;
			});
		}
	}
	.col-right {
		flex: 0 0 638px;
		max-width: 638px;
		.respMin(1441px,{
			flex: 0 0 49%;
			max-width: 49%;
		});
		.resp(1200px,{
			flex: 1 1 auto;
			max-width: none;
			min-width: 0;
		});
	}
	.container {
		position: relative;
		.respMin(1441px,{
			position: relative;
			max-width: 1680px;
			padding: 0;
		});
		&:after {
			position: absolute;
			left: 100%;
			margin-left: 150px;
			top: 0;
			width: 9999px;
			height: 100%;
			background: @bg;
			content: '';
			.respMin(1441px,{
				margin-left: -20px;
			});
		}
	}
	.row {
		align-items: flex-end;
		.resp(999px,{
			display: flex;
			align-items: flex-start;
		});
		.resp(767px,{
			display: block;
		});
	}
}

.reviews-carousel {
	min-height: 420px;
	margin-bottom: 25px;
	.resp(1279px,{
		min-height: 375px;
	});
	.resp(999px,{
		min-height: 0;
		margin: 0;
	});
}

.reviews-carousel-wrapper {
	.respMin(1441px,{
		max-width: 604px;
	});
}

.reviews-nav {
	display: flex;
	justify-content: flex-end;
	.resp(767px,{
		display: none;
	});
	.carousel-nav {
		flex: 0 0 auto;
	}
}
