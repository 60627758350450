.other {
	position: relative;
	z-index: 1;
	.section;
	margin-bottom: 140px;
	.resp(1440px,{
		margin-bottom: 100px;
	});
	.resp(1279px,{
		margin-bottom: 90px;
	});
	.resp(999px,{
		margin-bottom: 80px;
	});
	.resp(767px,{
		margin-bottom: 48px;
	});
	&:after {
		width: 1662px;
		position: absolute;
		left: 50%;
		top: 0;
		height: 100%;
		content: '';
		z-index: 1;
		background: @bg;
		transform: translateX(-50%);
	}
	&_light {
		&:after {
			background: #f6f6f6;
		}
		.container {
			&:after {
				.resp(1399px,{
					width: 82px !important;
					height: 42px !important;
					right: 0 !important;
				});
				.resp(767px,{
					display: none !important;
				});
			}
		}
	}
	.card {
		border-color: @bg;
	}
	.container {
		position: relative;
		z-index: 5;
		&:after {
			position: absolute;
			right: -220px;
			top: 0;
			width: 140px;
			height: 70px;
			background: url('../img/lines.svg') no-repeat;
			background-size: 100% 100%;
			content: '';
		}
	}

	&--variant-2 {
		overflow: hidden;
		background-color: #f6f6f6;

		.owl-stage-outer {
			overflow: visible !important;
		}

		.owl-stage {
			display: flex;
		}

		.owl-item {
			height: auto;

			.card {
				height: 100%;
				min-height: 302px;

				.card-main {
					.resp(1279px,{
						padding: 40px;
					});
				}
			}

			.card .card-main:after {
				left: 40px;
				bottom: 40px;
				border: 1px solid #ff3f23;
				.svg('rarr',@red);
				background-size: 18px 11px;
			}
		}

		&::after {
			display: none;
		}

		.container:after {
			display: none;
		}

		.owl-nav {
			margin-left: auto;
			margin-right: auto;
			margin-top: 48px;
			font-size: 0;
		}
	}
}
