.section-specifications {
	padding: 140px 0;

	.resp(1279px,{
		padding: 88px 0;
	});

	.resp(767px,{
		padding: 48px 0;
	});

	.container {
		display: flex;
		flex-direction: column;
	}

	&__inner {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		column-gap: 32px;

		.resp(1279px,{
			grid-template-columns: repeat(1, minmax(0, 1fr));
		});
	}

	&__col {
	}

	&__button {
		margin-left: auto;
		margin-right: auto;
		margin-top: 68px;

		.resp(767px,{
			margin-top: 40px;
		});
	}
}
