.accordion {
	border-bottom: 1px solid @gray;
	position: relative;
	padding: 4px 8px;

	&__toggle {
		min-height: 52px;
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: color @hover;
		padding-right: 60px;

		&:hover {
			color: @red;

			.accordion__arrow {
				stroke: @red;
			}
		}
	}

	&__content {
		width: 100%;
		padding-right: 60px;
		padding-bottom: 14px;
		display: none;
	}

	&__arrow {
		width: 42px;
		height: 42px;
		stroke: @gray;
		position: absolute;
		right: 8px;
		bottom: 10px;
		transition: stroke @hover, transform @hover;
		z-index: 1;
	}

	&.is-active {
		.accordion__toggle {
			color: @red;
		}

		.accordion__arrow {
			stroke: @red;
			transform: rotate(180deg);
		}

		.accordion__content {
			display: block;
		}
	}
}
