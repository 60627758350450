.content {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	.resp(999px,{
		display: block;
	});
	> article {
		flex: 1 1 auto;
		max-width: 748px;
		min-width: 0;
		.resp(999px,{
			max-width: none;
		});
	}
	h4 {
		margin-bottom: 16px;
	}
	p {
		margin: 16px 0;
	}
	.image{
		text-align: center;
	}
	.block-header {
		margin-bottom: 40px;
	}
	.flying-nav {
		flex: 0 0 402px;
		max-width: 402px;
		position: relative;
		margin-left: 60px;
		&.flying-nav-wide {
			flex: 0 0 438px;
			max-width: 438px;
			.resp(1200px,{
				flex: 0 0 360px;
				max-width: 360px;
			});
		}
		.resp(1200px,{
			flex: 0 0 300px;
			max-width: 300px;
		});
		.resp(999px,{
			display: none;
		});
	}
}

.flying-nav-box {
	width: 402px;
	.flying-nav-wide & {
		width: 438px;
	}
	.resp(1200px,{
		width: 300px;
		.flying-nav-wide & {
			width: 360px;
		}
	});
	&.absolute {
		position: absolute;
		bottom: 0;
	}
	&.fixed {
		position: fixed;
		top: 112px;
		.resp(1440px,{
			top: 80px;
		});
		.resp(999px,{
			top: 56px;
		});
	}
	&.not-flying {
		&,
		&.absolute,
		&.fixed {
			position: relative;
		}
	}
}

.content-products {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	margin-bottom: 72px;
	.resp(999px,{
		display: block;
		margin-bottom: auto;
	});
	> article {
		flex: 1 1 auto;
		max-width: 748px;
		min-width: 0;
		.resp(999px,{
			max-width: none;
		});
	}
	h4 {
		margin-bottom: 16px;
	}
	p {
		margin: 16px 0;
	}
	.image{
		text-align: center;
	}
	.block-header {
		margin-bottom: 40px;
	}
}