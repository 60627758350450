.section-news {
	.block-header {
		margin-bottom: 56px;
		.resp(1279px,{
			margin-bottom: 40px;
		});
		.resp(767px,{
			margin-bottom: 24px;
		});
	}
}



.news {
	margin: -36px -16px;
	display: flex;
	flex-wrap: wrap;
	.resp(767px,{
		display: block;
		margin: 0;
	});
	&.owl-carousel {
		margin: -16px;
		&.owl-loaded {
			margin: 0;
		}
		&:not(.owl-loaded) {
			align-items: stretch;
			.news-col {
				padding: 16px;
			}
		}
	}
	&:not(.owl-carousel) {
		.news-box {
	       .show-more {
				.news-text {
					max-height: 112px;
			        overflow: hidden;
			        .resp(999px,{
						max-height: 96px;
					});
			        @supports(-webkit-line-clamp: 4) {
			            display: -webkit-box;
			            -webkit-line-clamp: 4;
			            -webkit-box-orient: vertical;
			        }
				}
			}
			.show-more.is-opened {
				.news-text {
					max-height: none;
			        overflow: visible;
			        .resp(999px,{
						max-height: none;
					});
			        @supports(-webkit-line-clamp: 4) {
			            display: block;
			            -webkit-line-clamp: unset;
			            -webkit-box-orient: unset;
			        }
				}
			}
		}
	}
	.news-col {
		flex: 0 0 33.3333%;
		max-width: 33.3333%;
		box-sizing: border-box;
		padding: 36px 16px;
		display: flex;
		align-items: stretch;
		.resp(767px,{
			padding: 0;
			max-width: none;
			& + .news-col {
				margin-top: 40px;
			}
		});
		
		.news-box{
			.more-link{
			max-width: 27%;
			}
		}
		
	}
}

.news-box {
	display: flex;
	flex-direction: column;
	flex: 1 1 100%;
	max-width: 100%;
	> h4 {
		width: 100%;
		overflow: hidden;
		display: -webkit-box;
		//-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;

	}
	.cover {
		height: 200px;
		display: block;
		flex: 0 0 auto;
		width: 100%;
		.resp(1023px,{
			height: 147px;
		});
		.resp(767px,{
			height: 139px;
		});
	}
	.news-image {
		flex: 0 0 auto;
		display: block;
		margin-bottom: 48px;
		.resp(1279px,{
			margin-bottom: 24px;
		});
		> img {
			display: block;
			width: 100%;
		}
	}

	.news-iframe{
		position:relative;
		padding: 0 0 60.53% 0;
		height:0;
		overflow:hidden;
		margin-bottom: 48px;
		.resp(1279px,{
			margin-bottom: 24px;
		});
		> iframe {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}
	.news-main {
		padding-top: 32px;
		border-top: 0;
		flex: 1 1 auto;
		width: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.resp(1200px,{
			padding-top: 30px;
		});
		.resp(767px,{
			padding-top: 30px;
			display: block;
		});
		> h4 {
			flex: 1 1 auto;
			width: 100%;
			min-height: 0;
			margin-bottom: 16px;

		}
		.more-link {
			flex: 0 0 auto;
		}
	}
	.news-order {
		flex: 0 0 auto;
		margin-top: 46px;
		.resp(767px,{
			margin-top: 22px;
		});
	}
	.news-text {
		flex: 0 0 auto;
		width: 100%;
		margin-bottom: 24px;
		.resp(767px,{
			margin-bottom: 16px;
		});
	}
}
 .news-box.white{
	background-color: white;
	
	h4 {
		width: auto;
		margin: 0px 24px 24px 24px;
	}
	
 }