@font: 'Roboto', 'Arial', 'Helvetica', 'Nimbus Sans L', sans-serif;

@hover: 0.2s ease;
@move: 0.3s ease-in-out;

@red: #FF0030;
@redhover: #fa0f00;
@black: #333;
@blackhover: #000;
@gray: #bdbdbd;
@darkgray: #808080;
@lightgray: #f1efef;
@line: #e0e0e0;
@bg: #f1efef;
@white: #fff;

@retina: ~'(min-resolution: 124dpi), (-webkit-min-device-pixel-ratio: 1.3), (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 4/3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx)';
