.vcard-box.container{
	max-width: 1142px;
	min-height: 720px;

	.resp(1440px,{
		min-height: 600px;
	});

	.resp(999px,{
		min-height: 430px;
	});
}


.vcard {
	background: rgb(255, 255, 255);
	color: @black;
	margin-top: 15%;
	margin-bottom: 15%;
	padding: 80px;
	.resp(1440px,{
		margin-top: 10%;
	});

	.resp(1279px,{
		padding: 50px;
	});
	.resp(999px,{
		padding: 30px;
		margin-bottom: 10%;
	});
	.resp(767px,{
		padding: 32px 16px 32px 16px;

	});
	.vcard-contact {
		margin: 0 0 24px 0;
		padding: 0;
		list-style-type: none;
		> li {
			& + li {
				margin-top: 8px;
				.resp(767px,{
					margin-top: 16px;
				});
			}
			> a {
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	.vcard-download {
		font-weight: 500;
		display: inline-block;
		vertical-align: top;
		max-width: 100%;
		box-sizing: border-box;
		position: relative;
		padding-right: 25px;
		margin-top: 40px;
		.resp(1279px,{
			margin-bottom: 32px;
			margin-top: 32px;
		});

		.resp(767px,{
			display: none;
		});

		&:hover {
			color: @red;
			&:after {
				.svgcls('download',@red);
			}
		}
		&:after {
			position: absolute;
			width: 16px;
			height: 17px;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			content: '';
			.svgcls('download',@black);
			transition: background @hover;
		}
	}

	.vcard-download-mobile {
		font-weight: 500;
		display: none;
		vertical-align: top;
		max-width: 100%;
		box-sizing: border-box;
		position: relative;
		padding-right: 25px;


		.resp(767px,{
			display: inline-block;
			margin-top: 18px;
		});

		&:hover {
			color: @red;
			&:after {
				.svgcls('download',@red);
			}
		}
		&:after {
			position: absolute;
			width: 16px;
			height: 17px;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			content: '';
			.svgcls('download',@black);
			transition: background @hover;
		}
	}

	.vcard-image {
		position: relative;
		.resp(999px,{
			padding-top: 8px;
		});

		.resp(767px,{
			max-width: 260px;
		});

		.resp(479px,{
			padding: 0;
			margin: 32px auto 32px auto;
			max-width: 150px;
		});

		&:before {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 50%;
			content: '';
			background: @red;
			padding-bottom: 9%;
		}
		> img {
			width: 100%;
			display: block;
		}
	}
	.vcard-name {
		padding-top: 2px;
		font-size: 48px;
		line-height: 60px;
		margin-bottom: 40px;
		font-weight: bold;
		text-transform: uppercase;
		.resp(1279px,{
			font-size: 42px;
			line-height: 56px;
			margin-bottom: 24px;
		});
		.resp(999px,{
			font-size: 32px;
			line-height: 44px;
			padding-top: 0;
		});
		.resp(479px,{
			font-size: 20px;
			line-height: 26px;
			margin-bottom: 16px;
		});
		& + .vcard-position {
			margin-top: -24px;
			.resp(1279px,{
				margin-top: -16px;
			});
			.resp(479px,{
				margin-top: -8px;
			});
		}
	}
	.vcard-position {
		margin-bottom: 40px;
		.resp(1279px,{
			margin-bottom: 24px;
		});
		.resp(479px,{
			margin-bottom: 16px;
		});
	}
	.vcard-row {
		margin: 0 -40px;
		display: flex;
		.resp(1279px,{
			margin: 0 -25px;
		});
		.resp(999px,{
			margin: 0 -15px;
		});
		.resp(767px,{
			//display: block;
			margin: 0 -15px;
		});

		.resp(479px,{
			display: block;
			margin: 0px;
		});
		.vcard-col {
			//flex: 0 0 50%;
			//max-width: 50%;
			box-sizing: border-box;
			padding: 0 40px;
			.resp(1279px,{
				padding: 0 25px;
			});
			.resp(999px,{
				padding: 0 15px;
			});
			.resp(767px,{
				max-width: none;
				padding: 0 15px;
			});
		}
	}
	.vcard-text {
		margin-bottom: 24px;
		max-width: 520px;
	}
	.vcard-toolbox {
		margin: -4px;
		padding: 0;
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		.resp(999px,{
			margin-right: -10px;
		});
		.resp(767px,{
			margin: -8px;
		});
		> li {
			flex: 0 0 auto;
			margin: 4px;
			.resp(767px,{
				margin: 8px;
			});
			> a {
				width: 40px;
				height: 40px;
				box-sizing: border-box;
				display: block;
				border-radius: 50%;
				border: 1px solid @gray;
				transition: background @hover, border-color @hover;
				position: relative;
				&:hover {
					background: @red;
					border-color: @red;
				}
				&:before {
					position: absolute;
					left: 50%;
					top: 50%;
					content: '';
					transform: translate(-50%,-50%);
					transition: background @hover;
				}
			}
		}
		.document {
			&:hover {
				&:before {
					.svg('document',#FFF);
				}
			}
			&:before {
				width: 12px;
				height: 16px;
				.svg('document',@black);
			}
		}
		.fb {
			&:hover {
				&:before {
					.svg('fb',#FFF);
				}
			}
			&:before {
				width: 8px;
				height: 16px;
				.svg('fb',@black);
			}
		}
		.id-card {
			&:hover {
				&:before {
					.svg('id-card',#FFF);
				}
			}
			&:before {
				width: 20px;
				height: 15px;
				.svg('id-card',@black);
			}
		}
		.linkedin {
			&:hover {
				&:before {
					.svg('linkedin',#FFF);
				}
			}
			&:before {
				width: 16px;
				height: 16px;
				.svg('linkedin',@black);
			}
		}
		.tg {
			&:hover {
				&:before {
					.svg('tg',#FFF);
				}
			}
			&:before {
				width: 17px;
				height: 14px;
				.svg('tg',@black);
			}
		}
		.whatsapp {
			&:hover {
				&:before {
					.svg('whatsapp',#FFF);
				}
			}
			&:before {
				width: 17px;
				height: 17px;
				.svg('whatsapp',@black);
			}
		}
		.zoom {
			&:hover {
				&:before {
					.svg('zoom',#FFF);
				}
			}
			&:before {
				width: 19px;
				height: 10px;
				.svg('zoom',@black);
			}
		}
	}

	a{
		&:hover {
			color: @red;
		}
	}
}

.vcard-header {
	margin-bottom: 72px;
	.resp(1440px,{
		margin-bottom: 60px;
	});
	.resp(1279px,{
		margin-bottom: 40px;
	});
	.resp(999px,{
		margin-bottom: 30px;
	});
	.resp(767px,{
		margin-bottom: 16px;
	});
	.vcard-logo {
		display: block;
		width: 189px;
		height: 48px;
		background: url('../img/logo-white.svg') no-repeat;
		background-size: 100% 100%;
		.resp(767px,{
			width: 94px;
			height: 24px;
			background-image: url('../img/logo-black.svg');
		});
	}
}

.vcard-page {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 140px 0;
	box-sizing: border-box;
	.resp(1440px,{
		padding: 60px 0;
	});
	.resp(1279px,{
		padding: 40px 0;
	});
	.resp(999px,{
		padding: 30px 0;
	});
	.resp(767px,{
		padding: 16px 0 56px 0;
	});
	.iefix({
		height: 100%;
	});
	.container {
		margin: auto;
		flex: 1 1 100%;
		position: relative;
		z-index: 2;
	}
	.cover {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: '';
		z-index: 1;
	}
}
