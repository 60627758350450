.section-text-info {
	padding: 140px 0;

	.resp(1279px,{
		padding: 88px 0;
	});

	.resp(1023px,{
		background-color: #F6F6F6;
	});

	.resp(767px,{
		padding: 48px 0;
	});

	&__inner {
		display: flex;

		.resp(1023px,{
			flex-direction: column;
			row-gap: 24px;
		});
	}

	&__img {
		flex: 1;

		.resp(1279px,{
			flex: initial;
			flex-shrink: 0;
			width: 522px;
		});

		.resp(1023px,{
			width: 100%;
			max-width: 522px;
		});
	}

	&__content {
		flex: 1;
		padding-left: 88px;

		.resp(1279px,{
			flex: 1 1 auto;
			padding-left: 72px;
		});

		.resp(1023px,{
			padding-left: 0;
		});

		.block-header {
			margin-bottom: 16px;

			& + p {
				margin-top: 0;
			}
		}
	}

	&__title {
		margin-bottom: 0;
	}
}

.fon { 
	background-color: #E0E0E0;
}