.industry {
	background: @bg;
	overflow: hidden;
	.resp(767px,{
		background: none;
	});
	.container {
		padding-top: 140px;
		padding-bottom: 140px;
		position: relative;
		z-index: 1;
		.resp(1440px,{
			padding-top: 100px;
			padding-bottom: 100px;
		});
		.resp(1279px,{
			padding-top: 90px;
			padding-bottom: 90px;
		});
		.resp(999px,{
			padding-top: 80px;
			padding-bottom: 80px;
		});
		.resp(767px,{
			padding-top: 0;
			padding-bottom: 48px;
		});
	}
	&:before {
		position: absolute;
		right: 100%;
		top: 0;
		width: 9999px;
		height: 100%;
		content: '';
		background: #FFF;
		margin-right: 147px;
		.resp(999px,{
			display: none;
		});
	}
	.industry-row {
		display: flex;
		align-items: center;
		.resp(767px,{
			display: block;
		});
		&:before {
			position: absolute;
			right: 100%;
			top: 0;
			width: 9999px;
			height: 100%;
			content: '';
			background: #FFF;
			margin-right: 147px;
			z-index: 5;
			.resp(767px,{
				display: none;
			});
		}
		.industry-carousel {
			max-width: 734px;
			flex: 0 1 734px;
			position: relative;
			z-index: 1;
			.resp(1279px,{
				flex: 1 1 auto;
				min-width: 0;
				max-width: none;
			});
			&:before {
				position: absolute;
				right: 100%;
				top: -5%;
				width: 9999px;
				height: 110%;
				content: '';
				background: @bg;
				z-index: 2;
				.resp(767px,{
					display: none;
				});
			}
			.owl-stage-outer {
				overflow: visible;
			}
		}
		.industry-text {
			max-width: 400px;
			flex: 0 0 400px;
			margin-right: 72px;
			position: relative;
			z-index: 2;
			.resp(1279px,{
				max-width: 256px;
				flex: 0 0 256px;
			});
			.resp(999px,{
				margin-right: 40px;
			});
			.resp(767px,{
				max-width: none;
			});
			> p {
				max-width: 320px;
				.resp(767px,{
					max-width: none;
					margin-top: 0;
				});
			}
			.block-header {
				margin-bottom: 40px;
				.resp(1279px,{
					margin-bottom: 24px;
				});
				.resp(767px,{
					margin-bottom: 8px;
				});
				> span {
					display: block;
					.resp(767px,{
						display: inline;
					});
				}
			}
			.carousel-nav {
				margin-top: 40px;
				.resp(767px,{
					display: none;
				});
			}
			.owl-dots {
				display: none;
				.resp(767px,{
					display: block;
				});
			}
		}
	}
	&.industry-index {
		.resp(767px,{
			background: #f6f6f6;
		});
		.container {
			.resp(767px,{
				padding-top: 48px;
				padding-bottom: 48px;
			});
		}
		.industry-row {
			&:before {
				background: transparent;
			}
			.industry-text {
				margin-right: 168px;
				.resp(1279px,{
					margin-right: 72px;
				});
				.resp(767px,{
					margin-right: 0;
				});
				> p {
					max-width: 100%;
				}
			}
		}
		&--gray {
			background-color: #f6f6f6;
			.industry-carousel:before {
				background-color: #f6f6f6;
			}
		}
	}
}

.industry-slide {
	width: 324px;
	display: block;
	position: relative;
	.resp(1279px,{
		width: 232px;
	});
	.resp(767px,{
		width: auto;
		height: 218px;
		overflow: hidden;
	});
	&:hover {
		.industry-slide-hover {
			background: fade(@red,80%);
			.resp(767px,{
				background: fade(@black,45%);
			});
		}
	}
	> img {
		display: block;
		width: 100%;
	}
	.industry-slide-hover {
		opacity: 1;
		transition: background @hover;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 54px 32px;
		background: fade(@black,45%);
		color: #FFF;
		font-size: 24px;
		line-height: 36px;
		font-weight: 500;
		.resp(1279px,{
			padding: 30px 24px;
			font-size: 20px;
			line-height: 30px;
		});
		.resp(767px,{
			background: fade(@black,45%);
			font-size: 16px;
			line-height: 28px;
			padding: 40px 24px;
			opacity: 1;
		});
		&:after {
			position: absolute;
			left: 32px;
			bottom: 40px;
			width: 48px;
			height: 48px;
			box-sizing: border-box;
			border: 1px solid #FFF;
			border-radius: 50%;
			content: '';
			.svg('rarr',#FFF);
			background-position: center center;
			background-size: 18px 11px;
			.resp(1279px,{
				width: 40px;
				height: 40px;
				left: 24px;
				bottom: 24px;
			});
			.resp(767px,{
				left: 24px;
				bottom: 24px;
			});
		}
		&:before {
			position: absolute;
			left: 32px;
			top: 40px;
			width: 48px;
			height: 2px;
			background: #FFF;
			content: '';
			.resp(1279px,{
				left: 24px;
				top: 22px;
				width: 32px;
			});
			.resp(767px,{
				height: 1px;
				left: 24px;
				top: 32px;
				width: 32px;
			});
		}
	}
}
