.section-form-new {
	.resp(767px,{
		.container:after {
			right: -30px;
			width: 82px;
			height: 42px;
		}
	});

	&__inner {
		display: flex;
		align-items: center;
		column-gap: 64px;

		.resp(767px,{
			flex-direction: column;
			row-gap: 40px;
		});
	}

	&__left {
		flex: 1;

		p {
			margin: 0;
		}
	}

	&__title {
		margin-bottom: 16px;
	}

	&__right {
		width: 100%;
		flex: 1;
	}

	&__form {
		max-width: 464px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		row-gap: 16px;
		margin-left: auto;
		margin-right: auto;
	}

	&__submit {
		margin-top: 40px;

		.resp(767px,{
			width: 100%;
			margin-top: 16px;
		});
	}
}
