.products-partners {
	&__title {
		font-weight: 400;
		margin-bottom: 40px;

		.resp(1199px,{
			margin-bottom: 32px;
			font-size: 20px;
		});

		.resp(767px,{
			margin-bottom: 24px;
		});
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		gap: 32px;

		.resp(767px,{
			grid-template-columns: repeat(1, minmax(0, 1fr));
		});
	}
}
