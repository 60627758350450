.section-update {
	padding: 140px 0;
	background-color: #f6f6f6;

	.resp(1279px,{
		padding: 88px 0;
	});

	.resp(767px,{
		padding: 48px 0;
	});

	&__inner {
		display: flex;
		align-items: center;
		column-gap: 56px;

		.resp(1279px,{
			flex-direction: column;
			align-items: flex-start;
			row-gap: 40px;
		});

		.resp(767px,{
			row-gap: 24px;
		});
	}

	&__left {
		flex-shrink: 0;
		width: 400px;

		.resp(1279px,{
			width: 100%;
		});
	}

	&__title {
		margin-bottom: 0;
	}

	&__slider {
		flex: 1 1 auto;

		.resp(1279px,{
			width: 100%;
		});

		.owl-stage {
			display: flex;
		}
	}

	&__item {
		background-color: #fff;
		height: 100%;
		padding: 48px 32px 40px 32px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		position: relative;
		min-height: 500px;

		.resp(1279px,{
			min-height: 470px;
		});

		.resp(767px,{
			&::after {
				opacity: 0;
			}

			.section-update__item-title {
				transform: translateY(0);

				&::before {
					opacity: 1;
				}

				&::after {
					opacity: 0;
				}
			}

			.section-update__item-content {
				opacity: 1;
				pointer-events: initial;
			}

			.section-update__item-more {
				opacity: 1;
				pointer-events: initial;
			}
		});

		&:hover {
			&::after {
				color: @red;
			}
		}

		&::after {
			content: attr(data-version);
			position: absolute;
			bottom: -24px;
			right: 0;
			color: #f6f6f6;
			font-size: 160px;
			font-weight: 700;
			text-transform: uppercase;
			display: block;
			line-height: 1;
			transition: color @hover, opacity @hover;
			pointer-events: none;
		}

		&.active {
			&::after {
				opacity: 0;
			}

			.section-update__item-title {
				transform: translateY(0);

				&::before {
					opacity: 1;
				}

				&::after {
					opacity: 0;
				}
			}

			.section-update__item-content {
				opacity: 1;
				pointer-events: initial;
			}

			.section-update__item-more {
				opacity: 1;
				pointer-events: initial;
			}
		}
	}

	&__item-title {
		font-size: 24px;
		font-weight: 700;
		line-height: 36px;
		letter-spacing: 1.2px;
		text-transform: uppercase;
		transform: translateY(-16px);
		position: relative;
		transition: transform @hover;

		&::before {
			content: '';
			position: absolute;
			top: -16px;
			left: 0;
			width: 80px;
			height: 2px;
			background-color: @red;
			opacity: 0;
			transition: opacity @hover;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: -16px;
			left: 0;
			right: 0;
			width: 100%;
			height: 2px;
			background-color: @red;
			transition: opacity @hover;
		}

		& > span {
			color: @red;
		}
	}

	&__item-content {
		margin-bottom: 32px;
		margin-top: 16px;
		opacity: 0;
		transition: opacity @hover;
		pointer-events: none;

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;

			& > li {
				position: relative;
				line-height: 28px;
				padding-left: 16px;

				& + li {
					margin-top: 8px;
				}

				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 2px;
					width: 2px;
					height: 20px;
					background-color: @red;
				}
			}
		}
	}

	&__item-more {
		margin-top: auto;
		opacity: 0;
		pointer-events: none;
		transition: opacity @hover;
	}
}
