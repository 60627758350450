.section-rus-prod {
  .block-header {
    margin-bottom: 40px;

    .resp(1279px,{
      margin-bottom: 24px;
		});

    .resp(479px,{
      margin-bottom: 10px;
		});
  }
  .block-header + p {
    max-width: 770px;
    margin-bottom: 56px;

    .resp(1279px,{
      margin-bottom: 40px;
		});

    .resp(479px,{
      margin-top: 0;
      margin-bottom: 24px;
		});
  }

  .prod-import__header h3 {
    .resp(820px,{
      font-size: 18px;
		});
    .resp(767px,{
      font-size: 20px;
		});
  }

  .prod-import__header p {
    display: initial;
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
    height: initial;

    .resp(1150px,{
      height: 84px;
		});

    .resp(797px,{
      height: 112px;
		});

    .resp(767px,{
      height: auto;
		});
  }
}
